import React, {useContext, useEffect, useState} from 'react';
import "./MemberGroup.sass";
import {useSelector} from "react-redux";
import Picture from "../../../components/Picture";
import { AppContext } from "../../../context/AppContext";
import getMemberGroupApi from "../../../actions/getMemberGroup";
import {FiKey} from "react-icons/fi";

const MemberGroup = () => {
    const room = useSelector(state => state.io.room);
    const { i18n } = useContext(AppContext);
    const [members, setMembers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [offset, setOffset] = useState(0);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const limit = 10;

    useEffect(() => {
        if(room){
            setOffset(0);
            setShowLoadMore(false);
            setLoading(true);

            getMemberGroupApi(room._id, 0, limit).then(res => {
                if(res.data.error === 0 && res.data.data){
                    let result = res.data.data;
                    setLoading(false);
                    setMembers(result);

                    setOffset(e => e + limit);
                    if(result.length >= limit) setShowLoadMore(true);
                }
                
            }).catch(err => {
                setLoading(false);
                console.log(err)
            });
        }
    }, [room]);

    const getMoreMemberGroup = () => {
        getMemberGroupApi(room._id, offset, limit).then(res => {
            if(res.data.error === 0 && res.data.data){
                let result = res.data.data;
                setOffset(e => e + limit);
                setMembers(e => [...e, ...result]);
                setLoading(false);
                result.length >= limit ? setShowLoadMore(true) : setShowLoadMore(false);
            }
            
        }).catch(err => {
            setLoading(false);
            console.log(err)
        });
    }

    const getAvatarMember = (member) => {
        let avatar = member.picture?.location;

        if(member.username === 'admin'){
            avatar = room.channel.avatar;
        }

        if (member && avatar){
            return <img className="picture" src={avatar} alt="Avatar User"/>;        
        }
        else
            return <div className="img">{member.firstName.substr(0,1).toUpperCase()}</div>;
    };

    return (
        <div className="social-profile">
            {(!loading && members.length > 0) ? (
                    members.map(member => (
                        <div className="profile-title" style={{ marginBottom: '7px' }}>
                            <div className="profile conversation">
                                {getAvatarMember(member)}
                                {member.username === 'admin' && <FiKey className="social-icon"/>}
                            </div>
                            {member.username === 'admin' 
                            ? <div className="profile-name">
                                <span className="profile-name__title">{room.channel.nameApp} ({member.firstName})</span><br/>
                                <span className="profile-name__sub">Trưởng nhóm</span>
                            </div> 
                            : <div className="profile-name"><span className="profile-name__title">{member.firstName}</span><br/><span className="profile-name__sub">Thành viên</span></div>}
                        </div>))
                ) : <div className="text-center" style={{ padding: '20px' }}>Chưa có thành viên</div>}
                {showLoadMore && (<div className="load-more" style={{ marginBottom: '7px' }} onClick={() => getMoreMemberGroup()}>{i18n.t('view_more')}</div>)}
        </div>
    );
}

export default MemberGroup;

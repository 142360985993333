import React, {useEffect} from 'react';
import {Route, Switch, useLocation} from "react-router-dom";
import MessageNotificationPage from "./PageType/MessageNotificationPage";
import MessageTemplatePage from "./PageType/MessageTemplatePage";
import ScheduleMessagePage from "./PageType/ScheduleMessagePage";
import ConfigSendTemplatePage from "./PageType/ConfigSendTemplatePage";

const ContentCampaign = () => {

    const location = useLocation();

    const getTitlePage = () => {
        if(location.pathname === '/campaign/message-template'){
            return 'Tin nhắn marketing';
        }
        else if(location.pathname === '/campaign/message-notification'){
            return 'Tin nhắn định kỳ';
        }
        else if(location.pathname === '/campaign/schedule'){
            return 'Cấu hình gửi tin';
        }
        else if(location.pathname === '/campaign/config-send'){
            return 'Cấu hình gửi tin';
        }
    }

    useEffect(() => {

    }, []);

    return (
        <div className='page-statistic-content'>
            <div className='page-statistic-content-body' style={{ height: '100vh' }}>
                <div className='overview-statistic'>
                    <div className="overview-statistic-header">
                        <div className="title">{getTitlePage()}</div>
                    </div>
                    <div className="main-content">
                        <Switch>
                            <Route exact path="/campaign/message-template" component={props => <MessageTemplatePage {...props} />} />
                            <Route exact path="/campaign/message-notification" component={props => <MessageNotificationPage {...props} />} />
                            <Route exact path="/campaign/schedule" component={props => <ScheduleMessagePage {...props} />} />
                            <Route exact path="/campaign/config-send" component={props => <ConfigSendTemplatePage {...props} />} />
                        </Switch>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContentCampaign;

import React, {useContext} from 'react';
import "./Room.sass";
import {useGlobal} from "reactn";
import {Image} from "react-bootstrap";
import icons from "../../../assets"
import linkCustomer from "../../../actions/linkCustomer";
import { AppContext } from "../../../context/AppContext";
import getListTagByUserApi from "../../../actions/getListTagByUser";

const Customer = ({customer, detectCustomerAction, userCurrent, reloadRooms, setSearchResultCustomer, showAlertAction}) => {
    const setSearchPage = useGlobal('searchPage')[1];
    const { i18n } = useContext(AppContext);
    const setUserActiveTagGlobal = useGlobal('userActiveTag')[1];
    const setUserSelectedTagGlobal = useGlobal('userSelectedTag')[1];

    const getAllTag = (user_id) => {
        getListTagByUserApi({user_id}).then(res => {
            if(res.data.error === 0 && res.data.data){
                let result = res.data.data;
                setUserSelectedTagGlobal(result);
                const listTagShow = result && result.filter(e => e.isActive === true);
                setUserActiveTagGlobal(listTagShow);
            }
        }).catch(err => console.log('getListTagApi', err));
    }

    const handleLinkCustomer = () => {
        linkCustomer({
            mapping_id: userCurrent.mapping_id,
            user_id: userCurrent._id,
            social_id: userCurrent.userSocialId, 
            customer_id: customer.customer_id, 
            type_customer: customer.type, 
            type_social: userCurrent.source
        }).then(res => {
            if(res.data.error === 0 && res.data.data){
                detectCustomerAction(userCurrent._id);
                setSearchResultCustomer([]);
                setSearchPage('');
                reloadRooms();
                getAllTag(userCurrent._id);
                showAlertAction('success', res.data.message);
            }
            else{
                showAlertAction('warning', res.data.message);
            }
        }).catch(err => console.log(err));
    }

    const limitTitle = (title, limit = 20) => {
        let result = '';

        if(title){
            result = title.substr(0, limit);
            result += title.length > limit ? '...' : '';
        }

        return result;
    }

    return (
        <>
            <div className="item">
                <div className="left">
                    <p className="customer-name text-bold" title={customer.full_name}>{customer.full_name}</p>
                    <p className="customer-code">{customer.customer_code}</p>
                    <p className="customer-label">{customer.type === 'cpo' ? i18n.t('lead') : i18n.t('customer')}</p>
                </div>
                <div className={`right`}>
                    <p className="text-right"><button className="btn customer-link ml-10" onClick={() => handleLinkCustomer()}><Image width="16" src={icons.Link}/> {i18n.t('link')}</button></p>
                    <p className="text-right">{customer.phone}</p>
                    <p className="customer-email" title={customer.email}>{limitTitle(customer.email)}</p>
                </div>
            </div>
        </>
    );
}

export default Customer;

import React, {useEffect, useState} from 'react';
import { Table, Input, Button} from 'antd';
import campaignApi from "../../../../api/campaign.api";
import ModalAddTemplateNotification from "../Block/ModalAddTemplateNotification";
import { FiPlus, FiEdit, FiTrash2 } from 'react-icons/fi';
import { Switch, Image, Popconfirm, message } from 'antd';
import { useHistory } from 'react-router-dom';

const MessageNotificationPage = () => {
    const [templates, setTemplates] = useState([]);
    const [keyword, setKeyword] = useState("");
    const [loading, setLoading] = useState(false);
    const [reloadPage, setReloadPage] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [templateId, setTemplateId] = useState("");
    const history = useHistory();

    useEffect(() => {
        getTemplateList();
    }, [keyword]);

    useEffect(() => {
        if(reloadPage){
            getTemplateList();
        }
    }, [reloadPage]);

    const onSubmitSearch = () => {
        const searchText = keyword.trim();

        if(searchText === ''){
            getTemplateList();
            return;
        }
    }

    const handleKeyPress = event => {
        if (event.keyCode === 13) {
            if (event.type === "keydown" && !event.shiftKey){
                onSubmitSearch();
                event.preventDefault();
            }
        }
    }

    const getTemplateList = async () => {
        setLoading(true);
        
        try{
            let res = await campaignApi.getMessageNotification(keyword);
            let data = res.data.data;
            setTemplates(data);
            setLoading(false);
        }
        catch(ex){
            setLoading(false);
        }
    }

    const addMessageTemplate = () => {
        setTemplateId(null);
        setShowModal(true);
    }

    const showEditTemplate = (id) => {
        setTemplateId(id);
        setShowModal(true);
    }

    const removeTemplate = async (template_id) => {
        const response = await campaignApi.removeMessageTemplate({template_id});
        let result = response.data;

        if(!result.error){
            message.success('Xóa tin nhắn mẫu thành công!')
            history.push('/campaign/message-notification')
        }
        else{
            message.error('Xóa tin nhắn mẫu thất bại!')
        }
    }

    const columns = [
        {
            title: 'Kích hoạt',
            dataIndex: 'status',
            key: 'status',
            width: '8%',
            align: 'center',
            render: (dataIndexValue, record) => (record.status === 'active' ? (<Switch size="small" defaultChecked/>) : (<Switch size="small"/>))
        },
        {
            title: 'Tên mẫu',
            dataIndex: 'template_name',
            key: 'template_name',
            ellipsis: true,
            width: '30%',
            render: (dataIndexValue, record) => (<><Image style={{ width: '60px', marginRight: '10px', borderRadius: '5px' }} src={record.thumbnail} alt=""/> <span>{record.template_name}</span></>)
        },
        {
            title: 'Số lượt đã mời',
            dataIndex: 'sent',
            key: 'sent',
            align: 'center',
            width: '7%',
        },
        {
            title: 'Đã gửi thành công',
            dataIndex: 'send_success',
            key: 'send_success',
            align: 'center',
            width: '7%',
        },
        {
            title: 'Đã gửi thất bại',
            dataIndex: 'send_failed',
            key: 'send_failed',
            align: 'center',
            width: '7%',
        },
        {
            title: 'Số khách nhận đăng ký',
            dataIndex: 'customer_register',
            key: 'customer_register',
            align: 'center',
            width: '7%',
        },
        {
            title: 'Tỉ lệ gửi thành công',
            dataIndex: 'success_rate',
            key: 'success_rate',
            align: 'center',
            width: '7%',
        },
        {
            title: 'Hành động',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            width: '7%',
            render: (dataIndexValue, record) => (
                <>
                <FiEdit style={{ cursor: 'pointer', marginRight: '15px' }} onClick={() => showEditTemplate(record._id)}/>
                <Popconfirm
                    title="Xóa tin nhắn mẫu"
                    description="Bạn có muốn xóa tin nhắn mẫu này?"
                    onConfirm={() => removeTemplate(record._id)}
                    okText="Đồng ý"
                    cancelText="Hủy"
                >
                    <FiTrash2 style={{ cursor: 'pointer', color: 'red' }}/>
                </Popconfirm>
                </>
            )
        },
    ];

    return (
        <>
        <div className=''>
            <ModalAddTemplateNotification showModal={showModal} setShowModal={setShowModal} setReloadPage={setReloadPage} templateId={templateId}/>
            <div className="card">
                <div className="header">
                    <div className="title-desc">
                        <div className="title">Danh sách tin nhắn mẫu</div>
                        {/* <div className="desc">Thống kê tin nhắn của trang theo ngày</div> */}
                    </div>
                    <div className='interaction'></div>
                </div>
                <div className="content">
                    <div className="chart-container">
                        <div className="input-search" style={{ margin: '10px', display: 'flex', justifyContent: 'space-between' }}>
                            <div>
                                <Input
                                    style={{ width: '300px', marginRight: '5px' }}
                                    placeholder="Nhập từ khóa để tìm kiếm..."
                                    onChange={e => setKeyword(e.target.value)}
                                    onKeyPress={handleKeyPress} 
                                    onKeyDown={handleKeyPress}
                                    value={keyword}
                                />
                                <Button type="primary" onClick={() => onSubmitSearch()}>Tìm kiếm</Button>
                            </div>
                            <div>
                                <Button type="primary" onClick={() => addMessageTemplate()}><FiPlus/> Thêm mẫu tin nhắn</Button>
                            </div>
                        </div>
                        <Table columns={columns} loading={loading} dataSource={templates} pagination={{ pageSize: 10 }} />
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default MessageNotificationPage;

import axios from "axios";
import Config from "../../config";

const GetTemplateDetail = params => {
    const {templateId} = params
    return axios({
        method: "POST",
        url: (Config.url || '') + "/api/template/detail",
        data: {template_id: templateId}
    });
};

export default GetTemplateDetail;

import React, {useEffect, useState} from 'react';
import campaignApi from "../../../../api/campaign.api";
import TemplateReview from "../Block/TemplateReview";
import {
    FiSend
} from "react-icons/fi";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, DatePicker, Tabs, Select, Radio, Input, Space, Row, Col, message } from 'antd';
import { useHistory } from 'react-router-dom';

const ConfigSendTemplatePage = () => {
    const [form] = Form.useForm();
    const history = useHistory();

    const [valuesForm, setValuesForm] = useState({
        content_type: 'template',
        send_type: '24h',
        send_at: 'now',
        conditions: [],
        frequency: 1
    });

    let conditionType = [
        {
            value: 'tag',
            label: 'Tag',
        },
        {
            value: 'user_name',
            label: 'Tên người dùng',
        },
        {
            value: 'source',
            label: 'Loại kênh',
        },
        {
            value: 'customer_type',
            label: 'Loại khách hàng',
        }
    ];

    let selectSource = [
        {
            value: 'facebook',
            label: 'Facebook',
        },
        {
            value: 'zalo',
            label: 'Zalo',
        },
        {
            value: 'client',
            label: 'Website',
        }
    ];

    let selectCustomerType = [
        {
            value: 'lead',
            label: 'Khách hàng tiềm năng',
        },
        {
            value: 'customer',
            label: 'Khách hàng',
        },
        {
            value: 'not_linked',
            label: 'Chưa liên kết',
        }
    ];

    const clauseItem = [
        {
            value: 'equal',
            label: '=',
        },
        {
            value: 'not_equal',
            label: '!=',
        },
        {
            value: 'contains',
            label: 'contains',
        },
        {
            value: 'not_contains',
            label: 'does not contains',
        }
    ]

    const [clause, setClause] = useState({});

    const [reviewData, setReviewData] = useState({});

    const [showConditionValue, setShowConditionValue] = useState({});
    const [showListAccept, setShowListAccept] = useState(false);
    const [showSendAt, setShowSendAt] = useState(false);
    const [templateAccept, setTemplateAccept] = useState([]);
    const [templates, setTemplates] = useState([]);

    const config = {
        rules: [
            {
            type: 'object',
            required: true,
            message: 'Please select time!',
            },
        ],
    };

    useEffect(() => {
        getTemplateIsAccpet();
        getMessageTemplate();
    }, []);

    const getTemplateIsAccpet = async () => {
        let res = await campaignApi.getNotificationIsAccept();
        let data = res.data.data;

        let lists = data.map(e => ({
            value: e._id, 
            label: e.template_name
        }));

        setTemplateAccept(lists);
    }

    const getMessageTemplate = async () => {
        let res = await campaignApi.getMessageTemplate();
        let data = res.data.data;

        let lists = data.map(e => ({
            value: e._id, 
            label: e.template_name
        }));

        setTemplates(lists);
    }

    const onChangeSendType = (e) => {
        let value = e.target.value;

        if(value === 'follow_notification'){
            setShowListAccept(true);
        }
        else{
            setShowListAccept(false);
        }
    }

    const onChangeSendAt = (e) => {
        let value = e.target.value;

        if(value === 'schedule'){
            setShowSendAt(true);
        }
        else{
            setShowSendAt(false);
        }
    }

    const onChangeMessageTemplate = (templateId) => {
        getTemplateDetail(templateId);
    }

    const getTemplateDetail = async (id) => {
        let res = await campaignApi.getTemplateDetail({templateId: id});

        let detail = res.data.data;

        const data = {
            description: detail.description,
            frequency: detail.frequency,
            thumbnail: detail.thumbnail,
            template_button: detail.template_button,
            template_name: detail.template_name,
            template_title: detail.template_title,
            content_type: detail.content_type,
            default_action: detail.default_action,
            review_type: 'config_send'
        }

        setReviewData(data);
    }
    
    const onSubmitAdd = async (fieldsValue) => {
        let values = {
            ...fieldsValue
        }

        if(fieldsValue['datetime_picker']){
            values.datetime_picker = fieldsValue['datetime_picker'].format('YYYY-MM-DD HH:mm:ss')
        }

        let response = await campaignApi.configSendMessageCampaign(values);

        let result = response.data;

        if(!result.error){
            let data = result.data;
            let countUserIds = data.user_ids?.length || 0
            message.success(`Cấu hình gửi tin nhắn thành công! Có ${countUserIds} người dùng được gửi tin`);
            history.push('/campaign/schedule');
        }else{
            message.error(result.message);
        }
    }

    const onChangeSelectConditionType = (index, value) => {
        setShowConditionValue((prev) => ({
            ...prev,
            [index]: value, // Lưu trạng thái type cho mỗi điều kiện
        }));

        setClause((prev) => ({
            ...prev,
            [index]: value, // Lưu trạng thái type cho mỗi điều kiện
        }));
    };

    // const onChangeSelectConditionType = (value) => {
    //     if(value === 'source'){
    //         const clause = clauseItem.slice(0, 2)
    //         setClause(clause);
    //         setShowConditionValue('source')
    //     }
    //     else if(value === 'customer_type'){
    //         const clause = clauseItem.slice(0, 2)
    //         setClause(clause);
    //         setShowConditionValue('customer_type')
    //     }
    //     else{
    //         setClause(clauseItem);
    //         setShowConditionValue('value')
    //     }
    // }

    const renderSelectClause = (index) => {
        let options = [];
        if(showConditionValue[index] === 'source' || showConditionValue[index] === 'customer_type'){
            options = clauseItem.slice(0, 2)
        }
        else{
            options = clauseItem
        }

        return (
            <Select
                style={{width: '150px'}}
                // defaultValue={'equal'}
                placeholder="Điều kiện"
                options={options}
            />
        )
    }

    const renderConditionValue = (index) => {
        if(showConditionValue[index] === 'source'){
            return (
                <Select
                    style={{width: '300px'}}
                    // defaultValue={'zalo'}
                    placeholder="Loại kênh"
                    options={selectSource}
                />
            )
        }
        else if(showConditionValue[index] === 'customer_type'){
            return (
                <Select
                    style={{width: '300px'}}
                    // defaultValue={'lead'}
                    placeholder="Loại khách hàng"
                    options={selectCustomerType}
                />
            )
        }
        else{
            return (<Input style={{width: '300px'}} placeholder="Giá trị" />);
        }
    }

    const handleAddCondition = () => {
        // setClause(clauseItem);
        // setShowConditionValue('value')
    }

    const CustomerSendBlock = () => {
        return (
            <>
            <Form.Item name="template_id" rules={[{ required: true, message: 'Bạn chưa chọn mẫu tin nhắn'}]} label="Chọn mẫu tin nhắn">
                <Select
                    showSearch
                    style={{width: '300px'}}
                    placeholder="Chọn mẫu tin nhắn"
                    options={templates}
                    onChange={onChangeMessageTemplate}
                />
            </Form.Item>
            <Form.Item name="send_type" required label="Đối tượng gửi">
                <Radio.Group
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 8,
                    }}
                    onChange={onChangeSendType}
                    // value={value}
                    options={[
                        {
                            value: '24h',
                            label: 'Tin nhắn cho khách hàng vừa tương tác trong vòng 24h',
                        },
                        {
                            value: '7day',
                            label: 'Tin nhắn cho khách hàng vừa tương tác trong vòng 7 ngày',
                        },
                        {
                            value: 'follow_notification',
                            label: 'Tin nhắn cho khách hàng đồng ý nhận chủ đề thông báo',
                        },
                    ]}
                />

            </Form.Item>
            {showListAccept && (
                <Form.Item name="template_notification_id" rules={[{required: true, message: 'Bạn chưa chọn chủ đề thông báo định kỳ'}]} label="Chủ đề thông báo định kỳ">
                    <Select
                        showSearch
                        style={{width: '350px'}}
                        placeholder="Chọn chủ đề thông báo"
                        options={templateAccept}
                    />
                </Form.Item>
            )}
            <Form.Item label="Điều kiện"></Form.Item>
            <Form.List name="conditions" label="Điều kiện lọc">
                {(fields, { add, remove }) => (
                    <>
                    {fields.map(({ key, name, ...restField }, index) => (
                        <Space
                            key={key}
                            style={{
                                display: 'flex',
                                marginBottom: 8,
                            }}
                            align="baseline"
                        >
                        <Form.Item
                            {...restField}
                                name={[name, 'type']}
                                rules={[
                                {
                                    required: true,
                                    message: 'Bạn chưa chọn loại điều kiện',
                                },
                            ]}
                        >
                            <Select
                                style={{width: '200px'}}
                                placeholder="Loại điều kiện"
                                onChange={(value) => onChangeSelectConditionType(index, value)}
                                options={conditionType}
                            />
                        </Form.Item>
                        <Form.Item
                            {...restField}
                            name={[name, 'clause']}
                            rules={[
                            {
                                required: true,
                                message: 'Bạn chưa chọn điều kiện',
                            },
                            ]}
                        >
                            {renderSelectClause(index)}
                        </Form.Item>
                        <Form.Item
                            {...restField}
                            name={[name, 'value']}
                            rules={[
                                {
                                    required: true,
                                    message: 'Bạn chưa nhập giá trị',
                                },
                            ]}
                        >
                            {renderConditionValue(index)}
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                        </Space>
                    ))}
                    {fields.length < 3 && (
                        <Form.Item>
                            <Button type="dashed" onClick={() => add(handleAddCondition())} block icon={<PlusOutlined />}>
                            Thêm điều kiện
                            </Button>
                        </Form.Item>
                    )}
                    </>
                )}
                </Form.List>
            <Form.Item name="send_at" rules={[{required: true}]} label="Thời gian gửi">
                <Radio.Group
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 8,
                    }}
                    onChange={onChangeSendAt}
                    // value={value}
                    options={[
                        {
                            value: 'now',
                            label: 'Gửi ngay',
                        },
                        {
                            value: 'schedule',
                            label: 'Đặt lịch gửi',
                        }
                    ]}
                />

            </Form.Item>
            {showSendAt && (
                <Form.Item name="datetime_picker" label="Chọn thời gian gửi" {...config}>
                    <DatePicker showTime format="DD/MM/YYYY HH:mm" />
                </Form.Item>
            )}
            </>
        )
    }

    const ChooseMessageTemplateBlock = () => {
        return (
            <>
            <Row>
                <Col span={12}>
                </Col>
                <Col span={12}>
                    <div className="config-send-template-review">
                        <TemplateReview valuesForm={reviewData}/>
                    </div>
                </Col>
            </Row>
            </>
        )
    }

    const getTabItems = () => {
        let tabItems = [
            {
                label: '1. Đối tượng gửi',
                key: 'customer_send_block',
                children: <CustomerSendBlock/>
            },
            {
                label: '2. Chọn nội dung',
                key: 'choose_template_block',
                children: <ChooseMessageTemplateBlock/>,
            }
        ];

        return tabItems;
    }

    return (
        <Form
            form={form}
            // layout="vertical"
            labelAlign="left"
            labelCol={{
                span: 7,
            }}
            wrapperCol={{
                span: 16,
            }}
            labelWrap
            colon={false}
            initialValues={valuesForm}
            onFinish={onSubmitAdd} >
                <div className="card">
                    <div className="content">
                        <Row gutter={16}>
                            <Col span={12}>
                                {/* <Tabs items={getTabItems()} /> */}
                                <CustomerSendBlock/>
                            </Col>
                            <Col span={12}>
                                <Form.Item style={{ textAlign: 'right'}}>
                                    <Button type="primary" htmlType="submit"><FiSend size="16" style={{ marginRight: '5px' }}/> Gửi tin nhắn</Button>
                                </Form.Item>
                                <div className="config-send-template-review">
                                    <TemplateReview valuesForm={reviewData}/>
                                </div>
                                {/* <ChooseMessageTemplateBlock/> */}
                            </Col>
                        </Row>
                    </div>
                </div>
        </Form>
    );
};

export default ConfigSendTemplatePage;

import axios from "axios";
import Config from "../config";

const getMemberGroup = (room_id, offset = 0, limit = 10) => {
    return axios({
        method: "POST",
        url: (Config.url || '') + "/api/group/get-members",
        data: {room_id, offset, limit}
    });
};

export default getMemberGroup;

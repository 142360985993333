import React, {useState, useContext, useEffect} from "react";
import TemplateNotificationReview from "./TemplateNotificationReview";
import GetTemplateDetailApi from "../../../../api/campaign/GetTemplateDetail";
import SendMessageNotificationApi from "../../../../api/campaign/SendMessageNotification";
import { Col, Row, Modal, Button, message } from 'antd';
import {
    FiSend
} from "react-icons/fi";
import { useSelector } from "react-redux";

const ModalSendTemplate = ({showModal, setShowModal, templateId}) => {
    const [valuesForm, setValuesForm] = useState({
        template_button: 'Nhận tin nhắn',
        frequency: 1
    });

    const [loading, setLoading] = useState(false)

    const room = useSelector(state => state.io.room);

    useEffect(() => {
        if(templateId){
            getTemplateDetail(templateId);
        }
    }, [templateId]);

    const getTemplateDetail = async (id) => {
        let res = await GetTemplateDetailApi({templateId: id});

        let detail = res.data.data;

        const data = {
            description: detail.description,
            frequency: detail.frequency,
            thumbnail: detail.thumbnail,
            template_button: detail.template_button,
            template_name: detail.template_name,
            template_title: detail.template_title,
        }

        setValuesForm(data);
    }

    const sendMessageNotification = async () => {
        setLoading(true);
        let res = await SendMessageNotificationApi({templateId, userSocialId: room?.owner?.userSocialId, socialChanelId: room?.channel?.socialChanelId});

        let result = res.data;

        if(result.error === 0){
            message.success(`Gửi tin nhắn thành công!`);
            setShowModal(false);
        }
        else{
            message.error(`Gửi tin nhắn thất bại! ${result.data?.error?.error_user_msg || result.data?.error?.message}`);
        }
        
        setLoading(false)
    }

    return (
        <Modal
            open={showModal}
            id="modal-setting"
            title={`Gửi lời nhận thông báo định kỳ`}
            onCancel={() => setShowModal(false)}
            width={500}
            footer={null}
            centered>
                <Row>
                    <Col span={24}>
                        <TemplateNotificationReview valuesForm={valuesForm}/>
                    </Col>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <Col span={24} style={{ textAlign: 'right' }}>
                        <Button loading={loading} type="primary" onClick={() => sendMessageNotification()}><FiSend size="16" style={{ marginRight: '5px' }}/> Gửi tin nhắn</Button>
                    </Col>
                </Row>
        </Modal>
    )
};

export default ModalSendTemplate;

import React, {useState, useEffect} from 'react';
import "./FilterRoom.sass";
import {useGlobal} from "reactn";
import getListTagsApi from "../../../actions/getListTags";
import {FiCheck} from 'react-icons/fi';
import RangePickerFilter from "../../../components/RangePickerFilter";
import { useTranslation } from 'react-i18next';
import i18n from '../../../translate';
import { Card, Space, Collapse, Radio, Checkbox } from 'antd';

const FilterRoom = ({setShowDialogFilter}) => {
    const { t, i18n } = useTranslation();
    const [listTag, setListTag] = useState(false);
    const setPageCurrentRoom = useGlobal('pageCurrentRoom')[1];

    //Filter
    const [roomFilter, setRoomFilter] = useGlobal('roomFilter');

    useEffect(() => {
        getListTagsApi().then(res => setListTag(res.data.data)).catch(err => console.log(err));
    }, []);

    const handleClickShow = () => {
        setShowDialogFilter(current => !current);
    }

    const handleClickTag = (tagId) => {
        let data = [];
        
        if(tagId !== 'all'){
            data = roomFilter.tag_ids;
            !data.includes(tagId) ? data.push(tagId) : data.splice(data.indexOf(tagId), 1);
        }

        setRoomFilter({...roomFilter, tag_ids: data});

        //Reset page
        setPageCurrentRoom(1);

        // setShowDialogFilter(false);
    }

    const FilterTag = () => {
        let tags = roomFilter.tag_ids;
        return (<div className="wrapper">
            <div className="item" onClick={() => handleClickTag('all')}>
                <div className="color" style={{ background: '#fff' }}></div>
                <div className="tag-name">{t('all')}</div>
                {tags && tags.length === 0 && <div className="icon-check"><FiCheck/></div>}
            </div>
            {listTag.length > 0 && (
                listTag.map(tag => (
                    <div key={tag._id} className="item" onClick={() => handleClickTag(tag._id)}>
                        <div className="color" style={{ background: tag.color }}></div>
                        <div className="tag-name">{tag.name}</div>
                        {(tags && tags.includes(tag._id)) && <div className="icon-check"><FiCheck/></div>}
                    </div>))
            )}
        </div>)
    }

    //Filter Liên kết khách hàng
    const onChangeLinked = async (e) => {
        setRoomFilter({...roomFilter, linked_status: e.target.value});
    };

    const FilterLinked = () => {
        return (
            <Radio.Group onChange={onChangeLinked} value={roomFilter.linked_status}>
                <Space direction="vertical">
                    <Radio value={'all'}>Tất cả</Radio>
                    <Radio value={'not_linked'}>Chưa liên kết</Radio>
                    <Radio value={'customer_linked'}>Liên kết khách hàng</Radio>
                    <Radio value={'lead_linked'}>Liên kết khách hàng tiềm năng</Radio>
                </Space>
            </Radio.Group>
        )
    }

    //Filter Trạng thái hội thoại
    const onChangeStatus = (e) => {
        setRoomFilter({...roomFilter, status: e.target.value});
    };
    
    const FilterStatus = () => {
        return (
            <Radio.Group onChange={onChangeStatus} value={roomFilter.status}>
                <Space direction="vertical">
                    <Radio value={'all'}>Tất cả</Radio>
                    <Radio value={'not_seen'}>Chưa đọc</Radio>
                    <Radio value={'seen'}>Đã đọc</Radio>
                    <Radio value={'replied'}>Đã trả lời</Radio>
                </Space>
            </Radio.Group>
        )
    }

    const onChangeGroup = (e) => {
        setRoomFilter({...roomFilter, is_group: e.target.checked});
    };

    const FilterCreatedAt = () => {
        return (
            <>
                <RangePickerFilter/>
                <Checkbox checked={roomFilter.is_group} style={{ marginTop: '15px' }} onChange={onChangeGroup}>Nhóm chat</Checkbox>
            </>
        )
    }

    //Filter (chọn nhiều)
    // const statusOptions = [
    //     {
    //         label: 'Chưa đọc',
    //         value: 'not_seen',
    //     },
    //     {
    //         label: 'Đã đọc',
    //         value: 'seen',
    //     },
    //     {
    //         label: 'Đã trả lời',
    //         value: 'replied',
    //     }
    // ];

    // const defaultStatusList = ['not_seen', 'seen', 'replied'];
    // const [statusCheckedList, setStatusCheckedList] = useState(defaultStatusList);
    // const checkAllStatus = statusOptions.length === statusCheckedList.length;
    // const indeterminateStatus = statusCheckedList.length > 0 && statusCheckedList.length < statusOptions.length;

    // const onChangeStatus = (checkedValues) => {
    //     setStatusCheckedList(checkedValues);
    //     setRoomFilter({...roomFilter, status: checkedValues});
    // };

    // const onCheckAllChangeStatus = (e) => {
    //     let target = [];

    //     if(e.target.checked){
    //         target = defaultStatusList;
    //     }

    //     setStatusCheckedList(target);
    //     setRoomFilter({...roomFilter, status: target});
    // };
    
    // const FilterStatus = () => {
    //     return (
    //         <>
    //             <Checkbox indeterminate={indeterminateStatus} onChange={onCheckAllChangeStatus} checked={checkAllStatus}>Tất cả</Checkbox>
    //             <CheckboxGroup options={statusOptions} value={statusCheckedList} onChange={onChangeStatus} />
    //         </>
    //     )
    // }
    

    const filterItems = [
        {
            key: '1',
            label: 'Ngày tiếp cận',
            children: <FilterCreatedAt/>,
        },
        {
            key: '2',
            label: 'Trạng thái liên kết',
            children: <FilterLinked/>,
        },
        {
            key: '3',
            label: 'Trạng thái hội thoại',
            children: <FilterStatus/>,
        },
        {
            key: '4',
            label: 'Nhãn trò chuyện',
            children: <FilterTag/>,
        },
    ];

    return (
        <div className="filter-wrap text-center">
            <Space direction="vertical" size={16}>
                <Card title="Bộ lọc" size="small" bordered={false} style={{ width: 359 }}>
                    <Collapse items={filterItems} defaultActiveKey={['1', '2', '3', '4']} />
                    <div className="btn btn-primary mt-2" onClick={() => handleClickShow()}>{t('hide')}</div>
                </Card>
            </Space>
        </div>
    );
}

export default FilterRoom;

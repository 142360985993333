import axios from "axios";
import Config from "../config";

const getLogs = (date) => {
    return axios({
        method: "GET",
        url: `${Config.url}/api/logs?date=${date}`
    });
};

export default getLogs;

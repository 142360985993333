import React, {useEffect, useState, useContext} from 'react';
import {useGlobal} from "reactn";
import {Link} from "react-router-dom";
import { Table, Spin, Tabs, Tag, Input, Button } from 'antd';
import Config from "../../../../config";
import LineChart from "../Chart/LineChart";
import ModalSummary from "../Block/ModalSummary";
import { AppContext } from '../../../../context/AppContext';
import ReportConversationTimeApi from "../../../../api/report/ReportConversationTime";
import ReportAdsDetailApi from "../../../../api/report/ReportAdsDetail";
import MasterConstant from "../../../../constants/MasterConstant";
import moment from "moment";

const AdsReport = () => {
    const { i18n } = useContext(AppContext);
    const [overviewChart, setOverviewChart] = useState();
    const [keyword, setKeyword] = useState("");
    const [chatTotal, setChatTotal] = useState({});
    const [adsCount, setAdsCount] = useState(0);
    const [adsDetail, setAdsDetail] = useState({});
    const [loading, setLoading] = useState(true);
    const [loadingAdsDetail, setLoadingAdsDetail] = useState(true);
    let brand = useGlobal('brand')[0];

    const selectedTime = useGlobal('selectedTimeReport')[0];
    const selectedChannel = useGlobal('selectedChannelReport')[0];

    useEffect(() => {
        const start_date = selectedTime[0];
        const end_date = selectedTime[1];

        ReportConversationTimeApi({channel_id: selectedChannel, start_date, end_date}).then(res => {
            let data = res.data.data;
            getDataOverViewLineChart(data);
            setChatTotal({
                totalRoom: data.totalRoom, 
                totalSessionActive: data.totalSessionActive,
                totalSessionClosed: data.totalSessionClosed
            });
            setLoading(false);
        })
        .catch(err => console.log(err));

        ReportAdsDetailApi({channel_id: selectedChannel, start_date, end_date}).then(res => {
            let data = res.data.data;
            setAdsDetail(data);
            setAdsCount(data.length);
            setLoadingAdsDetail(false);
        })
        .catch(err => console.log(err));
        
    }, [selectedTime, selectedChannel]);

    const optionsOverview = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
            title: {
                display: false,
                text: 'Chart.js Line Chart',
            },
            tooltip: {
                callbacks: {
                    title: function(context) {
                        let index = context[0].dataIndex;
                        let dataChart = overviewChart;
                        let date = dataChart.allDates[index];
                        date = date.split('-');
                        let day = date[2];
                        let month = date[1];
                        let year = date[0];

                        return `Ngày ${day}/${month}/${year}`;
                    }
                }
            }
        },
        animation: {
            duration: 1000 // Animation duration in milliseconds
        },
        scales: {
            x: {
                grid: {
                    display: false, // Ẩn đường kẻ dọc trên trục x
                },
            },
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Tin nhắn' // Label mô tả cho trục x
                }
            }
        },
        maintainAspectRatio: false,
        aspectRatio: 1
    };

    const getDataOverViewLineChart = (data) => {
        const result = {
            allDates: data.allDates,
            labels: data.labels,
            datasets: [
                {
                    type: 'line',
                    label: 'Phiên chat',
                    data: data?.valueSessions,
                    borderColor: 'rgb(255, 99, 132)',
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                    tension: 0.3
                },
                {
                    type: 'bar',
                    label: 'Cuộc hội thoại',
                    data: data?.valueRooms,
                    borderColor: 'rgb(53, 162, 235)',
                    backgroundColor: 'rgba(53, 162, 235, 0.5)',
                    tension: 0.3
                }
            ],
        };

        setOverviewChart(result);
    }

    const getAvatar = (record) => {
        let customerName = record.social_name;

        if(record.isGroup && record.avatar){
            return (
                <div className='d-flex align-items-center'>
                    <div className='image-box'>
                        <img width="50" src={record.avatar} alt=""/>
                    </div>
                    <Link to={`/room/${record.room_id}`} className="un-link">{customerName}</Link>
                </div>
            )
        }

        if(record.avatar){
            return (
                <div className='d-flex align-items-center'>
                    <div className='image-box'>
                        <img width="50" src={`${Config.url || ''}/api/images/${record.avatar}/256/${brand}`} alt=""/>
                    </div>
                    <Link to={`/room/${record.room_id}`} className="un-link">{customerName}</Link>
                </div>
            )
        }
        else{
            return (
                <div className='d-flex align-items-center'>
                    <div className='image-box'>
                        <div className="img">{customerName ? customerName.substr(0,1).toUpperCase() : ""}</div>
                    </div>
                    <Link to={`/room/${record.room_id}`} className="un-link">{customerName}</Link>
                </div>
            )
        }
    }

    const ReportDetailAdsTable = () => {
        if(loadingAdsDetail) return <></>;

        const columns = [
            {
                title: 'ID Quảng cáo',
                dataIndex: 'ad_id',
                key: 'ad_id'
            },
            {
                title: 'Tổng tin nhắn',
                dataIndex: 'count',
                key: 'count',
            },
            {
                title: 'Ghi nhận gần nhất',
                dataIndex: 'last_update',
                key: 'last_update',
            },
        ];

        const data = [];
        for (const i in adsDetail) {
            data.push({
                key: i.toString(),
                ad_id: adsDetail[i].ad_id,
                count: adsDetail[i].count,
                last_update: adsDetail[i].last_update,
                items: adsDetail[i].items
            });
        }   

        return (
            <Table
                columns={columns}
                pagination={false}
                bordered
                expandable={{
                    expandedRowRender,
                    defaultExpandedRowKeys: ['0'],
                }}
                dataSource={data}
            />
        )
    }

    const expandedRowRender = (record) => {
        if(loadingAdsDetail) return <></>;

        const columns = [
            {
                title: 'Người dùng',
                dataIndex: 'social_name',
                key: 'social_name',
                render: (index, record) => getAvatar(record)
            },
            {
                title: 'Nội dung',
                dataIndex: 'content',
                key: 'content',
            },
            {
                title: 'Ngày nhắn tin',
                dataIndex: 'date_time',
                key: 'date_time',
            },
        ];

        const items = record.items;
        const data = [];
        for (const i in items) {
            data.push({
                key: i.toString(),
                social_name: items[i].social_name,
                avatar: items[i].avatar,
                content: items[i].content,
                date_time: items[i].date_time,
            });
        }   

        return <Table 
            columns={columns} 
            dataSource={data} 
            pagination={false}
            bordered
        />;
    };

    const itemTabs = [
        {
            key: '1',
            label: 'Theo cuộc hội thoại',
            children: <Spin tip="Loading..." spinning={loadingAdsDetail}>{loadingAdsDetail ? <div className="loading-content"/> : <ReportDetailAdsTable/>}</Spin>,
        }
    ];

    const onSubmitSearch = () => {
        let text = keyword.trim();

        const start_date = selectedTime[0];
        const end_date = selectedTime[1];

        ReportAdsDetailApi({channel_id: selectedChannel, keyword: text, start_date, end_date}).then(res => {
            let data = res.data.data;
            setAdsDetail(data);
            setLoadingAdsDetail(false);
        })
        .catch(err => console.log(err));
    }

    const handleKeyPress = event => {
        if (event.keyCode === 13) {
            if (event.type === "keydown" && !event.shiftKey){
                onSubmitSearch(keyword);
                event.preventDefault();
            }
        }
    }

    return (
        <>
        {/* <div className='activity-overview'>
            <div className="card">
                <div className="header">
                    <div className="title-desc">
                        <div className="title">Biểu đồ tổng quan</div>
                        <div className="desc">Thống kê cuộc hội thoại đến từ quảng cáo theo ngày</div>
                    </div>
                    <div className='interaction'></div>
                </div>
                <div className="content">
                    <div className="chart-container">
                        <Spin tip="Loading..." spinning={loading}>{loading ? <div className="loading-content"/> : <LineChart options={optionsOverview} data={overviewChart}/>}</Spin>
                    </div>
                </div>
            </div>
        </div> */}
        <div className='view-ratio-statistic'>
            <div className="view-ratio-item" style={{ maxWidth: '300px' }}>
                <img src="https://pancake.vn/static/images/statistic/page/new-phone-number.svg" className="img-icon" alt=""/>
                <div className='data-ratio'>
                    <div className='ratio-count'>
                        <span>{adsCount || 0}</span>
                    </div>
                    <div className='title-ratio'>Chiến dịch</div>
                </div>
            </div>
            {/* <div className="view-ratio-item" style={{ maxWidth: '300px' }}>
                <img src="https://pancake.vn/static/images/statistic/page/old-customer-interact.svg" className="img-icon" alt=""/>
                <div className='data-ratio'>
                    <div className='ratio-count'>
                        <span>{chatTotal?.totalSessionActive || 0}</span>
                    </div>
                    <div className='title-ratio'>Phiên chat đang mở</div>
                </div>
            </div>
            <div className="view-ratio-item" style={{ maxWidth: '300px' }}>
                <img src="https://pancake.vn/static/images/statistic/page/new-customer.svg" className="img-icon" alt=""/>
                <div className='data-ratio'>
                    <div className='ratio-count'>
                        <span>{chatTotal?.totalSessionClosed || 0}</span>
                    </div>
                    <div className='title-ratio'>Phiên chat đã hoàn thành</div>
                </div>
            </div> */}
        </div>
        <div className="card">
            <div className="header">
                <div className="title-desc">
                    <div className="title">Thống kê chi tiết</div>
                    <div className="desc">Thống kê chi tiết các chiến dịch quảng cáo</div>
                </div>
                <div className='interaction'></div>
            </div>
            <div className="content">
                <div className="input-search text-right">
                    <Input
                        style={{ width: '300px', marginRight: '5px' }}
                        placeholder="Nhập ID quảng cáo để tìm kiếm..."
                        onChange={e => setKeyword(e.target.value)}
                        onKeyPress={handleKeyPress} onKeyDown={handleKeyPress}
                        value={keyword}
                    />
                    <Button type="primary" onClick={() => onSubmitSearch(keyword)}>Tìm kiếm</Button>
                </div>
                <Tabs defaultActiveKey="1" items={itemTabs} />
            </div>
        </div>
        </>
    );
};

export default AdsReport;

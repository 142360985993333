import axios from "axios";
import Config from "../../config";

const GetTemplateByUser = (userSocialId) => {
    return axios({
        method: "POST",
        url: (Config.url || '') + "/api/message-notification/get-template-by-user",
        data: {user_social_id: userSocialId}
    });
};

export default GetTemplateByUser;

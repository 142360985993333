import React, {useState, useContext} from 'react';
import {useDispatch, useSelector} from "react-redux";
import "./Room.sass";
import {useGlobal} from "reactn";
import {Button, Form, Modal, Image} from "react-bootstrap";
import ColorPicker from "./ColorPicker";
import {FiPlus, FiDelete, FiCheck, FiArrowLeft} from "react-icons/fi";
import './TagModal.sass';
import getListTagByUserApi from "../../../actions/getListTagByUser";
import createTagApi from "../../../actions/createTag";
import removeTagUserApi from "../../../actions/removeTagUser";
import addTagUserApi from "../../../actions/addTagUser";
import icons from "../../../assets"
import { AppContext } from "../../../context/AppContext";

const TagModal = ({userChat, showModalAddTag, setShowModalAddTag, showAlertAction}) => {

    const [tagName, setTagName] = useState(""); 
    const [colorActive, setColorActive] = useState('#EF9A9A');
    const [userSelectedTagGlobal, setUserSelectedTagGlobal] = useGlobal('userSelectedTag');
    const [userActiveTag, setUserActiveTagGlobal] = useGlobal('userActiveTag');
    const { i18n } = useContext(AppContext);

    // useEffect(() => {
    //     getAllTag();
    // }, [])

    const getAllTag = () => {
        getListTagByUserApi({user_id: userChat._id}).then(res => {
            if(res.data.error === 0 && res.data.data){
                let result = res.data.data;
                setUserSelectedTagGlobal(result);
                const listTagShow = result && result.filter(e => e.isActive === true);
                setUserActiveTagGlobal(listTagShow);
            }
        }).catch(err => console.log('getListTagApi', err));
    }

    const handleAddTag = () => {
        let filtered = userSelectedTagGlobal.filter(el => el.isActive === true);
        let tag_ids = pluck(filtered, '_id');
        addTagUserApi({user_id: userChat._id, tag_ids})
            .then(res => {
                setShowModalAddTag(false);
                if(res.data.error === 0){
                    showAlertAction('success', res.data.message)

                    getAllTag();
                }
                else{
                    showAlertAction('warning', res.data.message);
                }
            }).catch(err => console.log(err));
    }

    const pluck = (arr, key) => arr.map(i => i[key]);

    const submit = (e) => {
        e.preventDefault();

        if(tagName === ''){
            setShowModalAddTag(false);
            showAlertAction('warning' , i18n.t('not_enter_tag_name'));
            return;
        }

        createTagApi({user_id: userChat._id, name: tagName, color: colorActive})
            .then(async (res) => {
                if(res.data.error === 0){
                    getAllTag();
                    setTagName("");
                }
                else{
                    setShowModalAddTag(false);
                    showAlertAction('warning', res.data.message);
                }
            })
            .catch(err => console.log(err));
    };

    const handleChangeListTag = (e, currentTag) => {
        let temp = userSelectedTagGlobal.map((tag, index) => {
            if(tag._id === currentTag._id){
                tag.isActive = e.target.checked ? true : false;
            }
            return tag
        });

        setUserSelectedTagGlobal(temp);
    };

    const handleRemoveTag = (tag) => {
        removeTagUserApi({tag_id: tag.tag_id, user_id: userChat._id}).then(res => {
            if(res.data.error === 0){
                setShowModalAddTag(false);
                showAlertAction('success', res.data.message);
                getAllTag();
            }
            else{
                setShowModalAddTag(false);
                showAlertAction('warning', res.data.message);
            }
        }).catch(err => console.log(err));
    }

    const renderListTags = userSelectedTagGlobal.length && userSelectedTagGlobal.map(tagRender => (
        <div key={tagRender.tag_id} className="tag-item">
            {/* <div className="checkbox-tag">
                <Form.Check 
                    type="checkbox"
                    value={tagRender._id} 
                    checked={tagRender.isActive} 
                    id={`checkbox-${tagRender._id}`}
                    onChange={(e) => {handleChangeListTag(e, tagRender)}}
                />
            </div> */}
            <div className="color" style={{background: tagRender.color || '#0067ac'}}></div>
            <div className="tag-name">
                <label htmlFor={`checkbox-${tagRender.tag_id}`}>{tagRender.name}</label>
                <span className="remove-tag" onClick={() => handleRemoveTag(tagRender)}><FiDelete/></span>
            </div>
        </div>
    ));

    return (
        <Modal
                show={showModalAddTag}
                onHide={() => setShowModalAddTag(false)} animation={false}
                // size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                id="modal-add-tag"
            >
                <Form noValidate>
                    <Modal.Header closeButton>
                        <div className="modal-title h6"><Image width="20" src={icons.AddTag}/> {i18n.t('add_tag')}</div>
                    </Modal.Header>
                    <Modal.Body>
                        <h6>{i18n.t('tag_list')}</h6>
                        <div className="create-tag">
                            <ColorPicker colorActive={colorActive} setColorActive={setColorActive}/>
                            <div className="input-tag">
                                <input className="uk-input" placeholder={i18n.t('enter_tag_name')} value={tagName} onChange={(e) => {setTagName(e.target.value)}} />
                            </div>
                            <button className="btn btn-primary" onClick={submit}><FiPlus/> {i18n.t('add')}</button>
                        </div>
                        <div className="list-tags">
                            {renderListTags}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowModalAddTag(false)}><FiArrowLeft/> Đóng</Button>
                        {/* <Button onClick={() => handleAddTag()}><FiCheck/> {i18n.t('save')}</Button> */}
                    </Modal.Footer>
                </Form>
            </Modal>
    );
}

export default TagModal;

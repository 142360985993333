import React, {useState, useContext, useEffect} from "react";
import { AppContext } from '../../../../context/AppContext';
import { InfoCircleOutlined } from '@ant-design/icons';
import TemplateNotificationReview from "./TemplateNotificationReview";
import AddMessageTemplateApi from "../../../../api/campaign/AddMessageTemplate";
import GetTemplateDetailApi from "../../../../api/campaign/GetTemplateDetail";
import EditTemplateApi from "../../../../api/campaign/EditTemplate";
import { Col, Row, Modal, Button, Form, Input, Select, Upload, Switch, message } from 'antd';
import ImgCrop from 'antd-img-crop';
import axios from 'axios';
import Config from "../../../../config";
const { TextArea } = Input;

const ModalAddTemplateNotification = ({showModal, setShowModal, setReloadPage, templateId = null}) => {
    const { i18n } = useContext(AppContext);
    const [form] = Form.useForm();

    const [valuesForm, setValuesForm] = useState({
        template_button: 'Nhận tin nhắn',
        frequency: 1
    });

    const [imageUrl, setImageUrl] = useState("");
    const [fileList, setFileList] = useState([]);

    useEffect(() => {
        if(templateId){
            getTemplateDetail(templateId);
        }
        else{
            form.setFieldsValue({
                description: '',
                frequency: '',
                template_button: 'Nhận tin nhắn',
                template_name: '',
                template_title: '',
            });
            setValuesForm({});
            setFileList([]);
        }
    }, [templateId]);

    const getTemplateDetail = async (id) => {
        let res = await GetTemplateDetailApi({templateId: id});

        let detail = res.data.data;

        const initialFileList = [
            {
                uid: "-1",
                name: "example.png",
                status: "done",
                url: detail.thumbnail,
            },
        ];

        form.setFieldsValue({
            thumbnail: initialFileList,
        });

        setFileList(initialFileList);
        setImageUrl(detail.thumbnail);

        form.setFieldsValue({
            description: detail.description,
            frequency: detail.frequency,
            template_button: detail.template_button,
            template_name: detail.template_name,
            template_title: detail.template_title,
        });

        const data = {
            description: detail.description,
            frequency: detail.frequency,
            thumbnail: detail.thumbnail,
            template_button: detail.template_button,
            template_name: detail.template_name,
            template_title: detail.template_title,
        }

        setValuesForm(data);
    }

    const onChangeUpload = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };

    const onPreviewUpload = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };

    const onSubmitAdd = async (values) => {
        if(imageUrl){
            values.thumbnail = imageUrl;
        }

        if(templateId){
            let response = await EditTemplateApi(templateId, values);

            if(!response.error){
                message.success(`Mẫu tin nhắn được cập nhật thành công!`);
                setShowModal(false);
                setReloadPage(true);
            }else{
                message.error(`Mẫu tin nhắn được cập nhật thất bại!`);
            }
        }
        else{
            values.template_type = 'notification';
            let response = await AddMessageTemplateApi(values);

            if(!response.error){
                message.success(`Mẫu tin nhắn được tạo thành công!`);
                setShowModal(false);
                setReloadPage(true);
            }else{
                message.error(`Mẫu tin nhắn được tạo thất bại!`);
            }
        }
    }

    const handleCustomRequest = async ({ file, onProgress, onSuccess, onError }) => {
        const formData = new FormData();
        formData.append("image", file);
    
        try {
            // Gửi file qua API
            const response = await axios.post(`${Config.url}/api/upload-image`, formData, {
            headers: { "brand-code": 'sale', "api-key": "62da77474991df7aa711a632" },
            onUploadProgress: (progressEvent) => {
                const percent = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                if (onProgress) {
                    onProgress({ percent });
                }
            },
            });
    
            // Xử lý thành công
            onSuccess(response.data);
            let imageUrl = response.data.data.location;
            setImageUrl(imageUrl);
            setValuesForm({...valuesForm, thumbnail: imageUrl});
            message.success(`${file.name} đã được tải lên thành công!`);
        } catch (error) {
            // Xử lý lỗi
            onError(error);
            message.error(`${file.name} tải lên thất bại.`);
    }
}

const beforeUpload = (file) => {
    const isImage = file.type === 'image/png' || file.type === 'image/jpeg';
    if (!isImage) {
        message.error('Chỉ cho phép upload file PNG hoặc JPG!');
    }

    return isImage; // Chỉ trả về true nếu loại file hợp lệ
};

    return (
        <Modal
            open={showModal}
            id="modal-setting"
            title={`Tạo tin nhắn mẫu`}
            onCancel={() => setShowModal(false)}
            width={900}
            footer={null}
            centered>
                <Form
                    form={form}
                    layout="vertical"
                    initialValues={valuesForm}
                    onValuesChange={(changedValues, allValues) => {
                        if(imageUrl){
                            allValues.thumbnail = imageUrl;
                        }
                        setValuesForm(allValues);
                    }}
                    onFinish={onSubmitAdd}
                    >
                    <Row>
                        <Col span={12}>
                            <Form.Item name="template_name" required label="Tên mẫu tin nhắn">
                                <Input placeholder="Nhập tên mẫu tin nhắn" />
                            </Form.Item>
                            <Form.Item 
                                label="Ảnh đại diện cho tin nhắn" 
                                name="thumbnail" 
                                valuePropName="fileList" 
                                getValueFromEvent={(e) => {
                                    // Lấy danh sách file từ sự kiện onChange
                                    if (Array.isArray(e)) {
                                        return e;
                                    }
                                    return e?.fileList;
                                }}
                                required 
                                tooltip="Bạn nên chọn ảnh hình vuông để tăng tỉ lệ chuyển đổi">
                                <ImgCrop rotationSlider>
                                    <Upload
                                        action={`${Config.url}/api/upload-image`}
                                        beforeUpload={beforeUpload}
                                        customRequest={handleCustomRequest}
                                        listType="picture-card"
                                        fileList={fileList}
                                        onChange={onChangeUpload}
                                        onPreview={onPreviewUpload}
                                    >
                                        {fileList.length < 1 && '+ Upload'}
                                    </Upload>
                                </ImgCrop>
                            </Form.Item>
                            <Form.Item name="template_title" required label="Tiêu đề">
                                <Input placeholder="Nhập tên tiêu đề" />
                            </Form.Item>
                            <Form.Item name="template_button" required label="Tên nút">
                            <Select
                                showSearch
                                placeholder="Select a person"
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={[
                                    {
                                        value: 'Nhận tin nhắn',
                                        label: 'Nhận tin nhắn',
                                    },
                                    {
                                        value: 'Cho phép nhận tin',
                                        label: 'Cho phép nhận tin',
                                    },
                                    {
                                        value: 'Nhận tin nhắn hàng ngày',
                                        label: 'Nhận tin nhắn hàng ngày',
                                    },
                                    {
                                        value: 'Chọn nhận tin nhắn',
                                        label: 'Chọn nhận tin nhắn',
                                    },
                                    {
                                        value: 'Đăng ký nhận tin',
                                        label: 'Đăng ký nhận tin',
                                    },
                                ]}
                            />
                            </Form.Item>
                            <Form.Item name="description" label="Gửi xác nhận đã đăng ký lần đầu">
                                {/* <Switch defaultChecked size="small" onChange={onChangeSwitch} /> */}
                                <TextArea rows={3} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <TemplateNotificationReview valuesForm={valuesForm}/>
                        </Col>
                    </Row>
                    <Form.Item style={{ textAlign: 'right' }}>
                        <Button type="primary" htmlType="submit">Lưu tin nhắn</Button>
                    </Form.Item>
                </Form>
        </Modal>
    )
};

export default ModalAddTemplateNotification;

import React, {useEffect, useState} from 'react';
import {useGlobal} from "reactn";
import { Table, Modal, Tabs, Spin } from 'antd';
import {Link} from "react-router-dom";
import Config from "../../../../config";
import moment from "moment";
import ReportDetailPageByDateApi from "../../../../api/report/ReportDetailPageByDate";
import icons from '../../../../assets';

const ModalDetail = ({selectedChannel, dateRow, isModalOpen, onClose}) => {
    const [dataRow, setDataRow] = useState({});
    const [loading, setLoading] = useState(true);
    const [countRoomAds, setCountRoomAds] = useState(0);
    const [countNewRoom, setCountNewRoom] = useState(0);
    const [countOldRoom, setCountOldRoom] = useState(0);
    const [countMessagePage, setCountMessagePage] = useState(0);
    const [countMessageCustomer, setCountMessageCustomer] = useState(0);
    let brand = useGlobal('brand')[0];
    const parentDomain = useGlobal('parentDomain')[0];

    useEffect(() => {
        ReportDetailPageByDateApi({channel_id: selectedChannel, date: dateRow}).then(res => {
            let data = res.data.data;

            if(data){
                setCountRoomAds(data.message_ads?.length || 0);
                setCountNewRoom(data.new_room?.length || 0);
                setCountOldRoom(data.old_room?.length || 0);
                setCountMessagePage(data.message_page?.length || 0);
                setCountMessageCustomer(data.message_customer?.length || 0);
                setDataRow(data);
                setLoading(false);
            }
        })
        .catch(err => console.log(err));
    }, [selectedChannel, dateRow]);

    const renderAvatar = (record) => {
        let social_name = record?.social_name;
        let avatar = record?.avatar;
        let customer_id = record?.customer_id;
        let lead_id = record?.lead_id;
        let full_name = record?.full_name;

        return (
            <div className='d-flex align-items-center'>
                <div className='image-box'>
                    {avatar ? <img width="50" src={`${Config.url || ''}/api/images/${avatar}/256/${brand}`} alt=""/> : <div className="img">{social_name.substr(0,1).toUpperCase()}</div>}
                </div>
                <div className='info-box'>
                    {customer_id ? <img className="mr-2" width="16" src={icons.Customer} alt=""/> : (lead_id ? <img className="mr-2" width="16" src={icons.CpoCustomer} alt=""/> : '') }
                    <Link to={`/room/${record.id}`} className="un-link" style={{ fontWeight: 600 }}>{social_name}</Link>
                    {customer_id 
                    ? <a href={`${parentDomain}/admin/customer/detail/${customer_id}`} className="un-link" style={{ color: '#0048ac', marginLeft: '5px' }} target="_blank" rel="noreferrer">({full_name})</a>
                    : (lead_id ? <a href={`${parentDomain}/customer-lead/customer-lead/detail/${lead_id}`} className="un-link" style={{ color: '#0048ac', marginLeft: '5px' }} target="_blank" rel="noreferrer">({full_name})</a> : '')}
                </div>
            </div>
        )
    }

    const renderContentMessage = (record) => {
        if(record.type === 'image'){
            return '<Tin nhắn ảnh>'
        }

        return record.content;
    }

    const onChangeTab = () => {}

    const RoomAdsTable = () => {
        if(loading) return;
        
        const data = dataRow.message_ads;

        if(!data){
            return;
        }
        
        const fixedColumns = [
            {
                title: 'Người dùng',
                dataIndex: 'social_name',
                fixed: true,
                render: (dataIndexValue, record) => renderAvatar(record)
            },
            {
                title: 'Ad ID',
                dataIndex: 'ad_id',
            },
            {
                title: 'Nội dung',
                dataIndex: 'content',
                render: (dataIndexValue, record) => renderContentMessage(record)
            },
            {
                title: 'Ngày nhắn tin',
                dataIndex: 'created_at',
            },
        ];

        let fixedData = [];

        for (const index in data) {
            fixedData.push({
                key: parseInt(index),
                social_name: data[index].social_name,
                avatar: data[index].avatar,
                ad_id: data[index].ad_id,
                type: data[index].type,
                content: data[index].content,
                created_at: moment(data[index].date_time).format('DD/MM/YYYY HH:mm'),
            });
        }   

        return (
            <Table
                columns={fixedColumns}
                dataSource={fixedData}
                pagination={false}
                scroll={{
                    // x: 2000,
                    y: 500,
                }}
                bordered
            />
        )
    }

    const NewRoomTable = () => {
        if(loading) return;
        
        const data = dataRow.new_room;

        if(!data){
            return;
        }
        
        const fixedColumns = [
            {
                title: 'Người dùng',
                dataIndex: 'social_name',
                fixed: true,
                render: (dataIndexValue, record) => renderAvatar(record)
            },
            {
                title: 'Ngày tiếp cận',
                dataIndex: 'created_at',
            },
            {
                title: 'Ngày tương tác gần nhất',
                dataIndex: 'message_at',
            },
        ];

        let fixedData = [];

        for (const index in data) {
            fixedData.push({
                key: parseInt(index),
                id: data[index]._id,
                social_name: data[index].social_name,
                full_name: data[index].full_name,
                phone: data[index].phone,
                customer_id: data[index].customer_id,
                lead_id: data[index].lead_id,
                avatar: data[index].avatar,
                message_at: moment(data[index].createdAt, 'YYYY-MM-DD').format('DD/MM/YYYY'),
                created_at: moment(data[index].createdAt, 'YYYY-MM-DD').format('DD/MM/YYYY'),
            });
        }   

        return (
            <Table
                columns={fixedColumns}
                dataSource={fixedData}
                pagination={false}
                scroll={{
                    // x: 2000,
                    y: 500,
                }}
                bordered
            />
        )
    }

    const OldRoomTable = () => {
        if(loading) return;
        
        const data = dataRow.old_room;

        if(!data){
            return;
        }
        
        const fixedColumns = [
            {
                title: 'Người dùng',
                dataIndex: 'social_name',
                fixed: true,
                render: (dataIndexValue, record) => renderAvatar(record)
            },
            {
                title: 'Ngày tiếp cận',
                dataIndex: 'created_at',
            },
            {
                title: 'Ngày tương tác gần nhất',
                dataIndex: 'message_at',
            },
        ];

        let fixedData = [];

        for (const index in data) {
            fixedData.push({
                key: parseInt(index),
                id: data[index]._id,
                social_name: data[index].social_name,
                full_name: data[index].full_name,
                phone: data[index].phone,
                customer_id: data[index].customer_id,
                lead_id: data[index].lead_id,
                avatar: data[index].avatar,
                message_at: moment(data[index].date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
                created_at: moment(data[index].roomCreated, 'YYYY-MM-DD').format('DD/MM/YYYY'),
            });
        }   

        return (
            <Table
                columns={fixedColumns}
                dataSource={fixedData}
                pagination={false}
                scroll={{
                    // x: 2000,
                    y: 500,
                }}
                bordered
            />
        )
    }

    const MessageCustomerTable = () => {
        if(loading) return;
        
        const data = dataRow.message_customer;

        if(!data){
            return;
        }
        
        const fixedColumns = [
            {
                title: 'Người dùng',
                dataIndex: 'social_name',
                fixed: true,
                render: (dataIndexValue, record) => renderAvatar(record)
            },
            {
                title: 'Nội dung',
                dataIndex: 'content',
                render: (dataIndexValue, record) => renderContentMessage(record)
            },
            {
                title: 'Ngày nhắn tin',
                dataIndex: 'created_at',
            },
        ];

        let fixedData = [];

        for (const index in data) {
            fixedData.push({
                key: parseInt(index),
                social_name: data[index].social_name,
                avatar: data[index].avatar,
                type: data[index].type,
                content: data[index].content,
                created_at: moment(data[index].date_time).format('DD/MM/YYYY HH:mm'),
            });
        }   

        return (
            <Table
                columns={fixedColumns}
                dataSource={fixedData}
                pagination={false}
                scroll={{
                    // x: 2000,
                    y: 500,
                }}
                bordered
            />
        )
    }

    const MessagePageTable = () => {
        if(loading) return;
        
        const data = dataRow.message_page;

        if(!data){
            return;
        }
        
        const fixedColumns = [
            {
                title: 'Người dùng',
                dataIndex: 'social_name',
                fixed: true,
                // render: (dataIndexValue, record) => renderAvatar(record)
            },
            {
                title: 'Nội dung',
                dataIndex: 'content',
                render: (dataIndexValue, record) => renderContentMessage(record)
            },
            {
                title: 'Ngày nhắn tin',
                dataIndex: 'created_at',
            },
        ];

        let fixedData = [];

        for (const index in data) {
            fixedData.push({
                key: parseInt(index),
                social_name: data[index].social_name,
                // avatar: data[index].avatar,
                type: data[index].type,
                content: data[index].content,
                created_at: moment(data[index].date_time).format('DD/MM/YYYY HH:mm'),
            });
        }   

        return (
            <Table
                columns={fixedColumns}
                dataSource={fixedData}
                pagination={false}
                scroll={{
                    // x: 2000,
                    y: 500,
                }}
                bordered
            />
        )
    }

    const itemTabs = [
        {
            key: '1',
            label: `Quảng cáo (${countRoomAds})`,
            children: <Spin tip="Loading..." spinning={loading}>{loading ? <div className="loading-content"/> : <RoomAdsTable/>}</Spin>,
        },
        {
            key: '2',
            label: `Người dùng mới (${countNewRoom})`,
            children: !loading && <NewRoomTable/>,
        },
        {
            key: '3',
            label: `Người dùng cũ tương tác lại (${countOldRoom})`,
            children: !loading && <OldRoomTable/>,
        },
        {
            key: '4',
            label: `Tin nhắn bởi người dùng (${countMessageCustomer})`,
            children: !loading && <MessageCustomerTable/>,
        },
        {
            key: '5',
            label: `Tin nhắn bởi trang (${countMessagePage})`,
            children: !loading && <MessagePageTable/>,
        },
    ];

    return (
        <Modal title={`Xem chi tiết ngày ${dateRow}`} width={1000} open={isModalOpen} onOk={onClose} onCancel={onClose}>
            <Tabs defaultActiveKey="1" items={itemTabs} onChange={onChangeTab} />
        </Modal>
    )
};

export default ModalDetail;

import React, {useEffect} from 'react';
import "./TemplateNotificationReview.sass";
import moment from 'moment'
import {useSelector} from "react-redux";

const TemplateNotificationReview = ({valuesForm}) => {
    const room = useSelector(state => state.io.room);

    useEffect(() => {
    }, [valuesForm]);

    return (
        <div className="popup-confirm-content__preview">
            <div className="preview-container">
                <div className="preview-container__screen">
                    <div className="preview-status-bar">
                        <div className="preview-status-bar__time"><span>{moment().format('HH:mm')}</span></div>
                        <div className="preview-status-bar__energy">
                            <div
                                className="svg-next-icon"
                                style={{ 
                                    backgroundColor: 'rgb(0, 0, 0)', 
                                    maskImage: 'url("https://static.harasocial.com/svg-funnel/ic-phone-status.svg")', 
                                    maskRepeat: 'no-repeat', maskSize: '54px 10px', width: '54px', height: '10px' }}
                            ></div>
                        </div>
                        <div className="preview-status-bar__white-block"></div>
                    </div>
                    <div className="preview-store-info">
                        <div className="preview-store-info__arrow-image">
                            <div className="svg-next-icon" style={{ 
                                backgroundColor: 'rgb(0, 0, 0)',
                                maskImage: "url('https://static.harasocial.com/svg-funnel/ic_back.svg')",
                                maskRepeat: 'no-repeat',
                                maskSize: '7px 12px',
                                width: '7px',
                                height: '12px',
                            }}></div>
                        </div>
                        <div className="preview-store-info__avatar">
                            <span content="" className="hrv-avatar hrv-avatar-circle hrv-avatar-image" style={{ 
                                width: '26px',
                                height: '26px',
                                lineHeight: '26px',
                                background: 'rgb(41, 121, 255)'
                            }}>
                                <img alt="" src={`https://graph.facebook.com/v21.0/${room?.channel.socialChanelId}/picture`} />
                            </span>
                        </div>
                        <div className="preview-store-info__name text-ellipsis"><span>{room?.channel?.nameApp}</span></div>
                    </div>
                    <div className="preview-container__body">
                        <div className="preview-messages">
                            <div className="row-wrapper">
                                <div className="">
                                    <span content="" className="hrv-avatar hrv-avatar-circle hrv-avatar-image" style={{ 
                                        width: '24px',
                                        height: '24px', 
                                        lineHeight: '24px', 
                                        background: 'rgb(41, 121, 255)'
                                    }}>
                                        <img alt="" src={`https://graph.facebook.com/v21.0/${room?.channel.socialChanelId}/picture`} />
                                    </span>
                                </div>
                                <div className="row-wrapper__children">
                                    <div className="card-notify">
                                        <div><img alt="in gallery" className="card-notify__image square" src={valuesForm.thumbnail || '//hstatic.net/0/0/global/social/default_product_large.png'} /></div>
                                        <div className="card-notify__info">
                                            <div>
                                                <p className="card-notify__title">{valuesForm.template_title}</p>
                                                <p className="m-0">Bạn muốn nhận thêm tin nhắn từ chúng tôi ư? Bất cứ lúc nào bạn cũng có thể chọn không nhận nữa.</p>
                                            </div>
                                            <div className="card__btn-container"><p>{valuesForm.template_button}</p></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row-wrapper__reply">
                                <div><span>{valuesForm.template_button}</span></div>
                            </div>
                            {valuesForm.description && 
                            <div className="row-wrapper">
                                <div className="">
                                    <span content="" className="hrv-avatar hrv-avatar-circle hrv-avatar-image" style={{ 
                                        width: '24px',
                                        height: '24px', 
                                        lineHeight: '24px', 
                                        background: 'rgb(41, 121, 255)',
                                    }}>
                                        <img alt="" src={`https://graph.facebook.com/v21.0/${room?.channel.socialChanelId}/picture`} />
                                    </span>
                                </div>
                                <div className="row-wrapper__children">
                                    <div className="card-text card-text--error-single-line">
                                        <div className="card-text__content">
                                            <span>{valuesForm.description}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                    <div className="preview-footer">
                        <div className="preview-footer__menu">
                            <div className="preview-footer__menu-item-1">
                                <div
                                    className="svg-next-icon"
                                    style={{ 
                                        backgroundColor: 'rgb(0, 132, 255)',
                                        maskImage: "url('https://static.harasocial.com/svg-funnel/ic-plus-circle-2.svg')",
                                        maskRepeat: 'no-repeat',
                                        maskSize: '18px 18px',
                                        width: '18px',
                                        height: '18px'
                                    }}
                                ></div>
                            </div>
                            <div className="preview-footer__menu-item-2">
                                <div
                                    className="svg-next-icon"
                                    style={{ 
                                        backgroundImage: "url('https://static.harasocial.com/svg-funnel/ic-input-preview-message.svg')",
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center center',
                                        backgroundSize: '176px 24px',
                                        width: '176px',
                                        height: '24px',
                                    }}
                                ></div>
                            </div>
                            <div className="preview-footer__menu-item-3">
                                <div
                                    className="svg-next-icon"
                                    style={{ 
                                        backgroundColor: 'rgb(0, 132, 255)',
                                        maskImage: "url('https://static.harasocial.com/svg-funnel/next-icon-menu.svg')",
                                        maskRepeat: 'no-repeat',
                                        maskSize: '14px 14px',
                                        width: '14px',
                                        height: '14px'
                                    }}
                                ></div>
                            </div>
                        </div>
                        <div className="preview-footer__bottom-bar"><div></div></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TemplateNotificationReview;

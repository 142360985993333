import axios from "axios";
import Config from "../../config";

const EditTemplate = (templateId, params) => {
    const {thumbnail, template_name, frequency, template_title, template_button, default_action, description} = params
    return axios({
        method: "POST",
        url: `${Config.url}/api/template/edit/${templateId}`,
        data: {thumbnail, template_name, frequency, template_title, template_button, default_action, description}
    });
};

export default EditTemplate;

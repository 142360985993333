import axios from "axios";
import Config from "../../config";

const AddMessageTemplate = params => {
    return axios({
        method: "POST",
        url: (Config.url || '') + "/api/template/add",
        data: params
    });
};

export default AddMessageTemplate;

import React, {useState, useEffect, useContext} from "react";
import './GroupInfo.sass';
import './ButtonDirect.sass';
import 'bootstrap/dist/css/bootstrap.min.css';
import {useGlobal} from "reactn";
import {FiEdit, FiSearch} from "react-icons/fi";
import {Image, Tooltip, OverlayTrigger} from "react-bootstrap";
import Loading from "./Loading";
import {useSelector} from "react-redux";
import icons from "../../../assets"
import { AppContext } from '../../../context/AppContext';

const GroupInfo = ({userChat, loadingCustomerInfo}) => {
    const room = useSelector(state => state.io.room);

    const { i18n } = useContext(AppContext);
    
    const setSearchPage = useGlobal('searchPage')[1];

    const Picture = ({room}) => {
        if (room && room.room_avatar)
            return <img src={room.room_avatar} alt="avatar group" />;

        return <div className="img">{room.room_name.substr(0,1)}</div>;
    };

    const CustomerInfomation = () => {
        return (
            <div className="customer-info-empty">
                <div className="customer-info">
                    <div className="title customer-info--phone"><Image width="16" src={icons.Phone}/><span>{userChat.phone}</span></div>
                </div>
            </div>
        )
    }

    return (
        <div className="block-ci">
            <div className="search-header">
                <button className="btn btn-search" onClick={() => setSearchPage('product')}><FiSearch/> {i18n.t('search_product')}</button>
                <button className="btn btn-search" onClick={() => setSearchPage('order')}><FiSearch/> {i18n.t('search_order')}</button>
            </div>
            {/* <div className="customer-info-btn">
                <button className={`btn action-update-title action-event`} onClick={() => actionEditUserChat()}><FiEdit size="16"/></button>
            </div> */}
            {loadingCustomerInfo && <Loading/>}
            {!loadingCustomerInfo && (
                <div className="customer-info-wrap">
                    <div className="customer-info-header">
                        <div className="profile">
                            <Picture room={room} userChat={userChat} />
                        </div>
                        <div className="customer-info-head-right">
                            <div className="userchat-name-block">
                                <span className="userchat-name">{room.room_name}</span>
                            </div>
                            <div className="customer-info--phone">Link tham gia: <a href={room.room_link} rel="noreferrer" target="_blank">{room.room_link}</a></div>
                        </div>
                    </div>
                    {/* <CustomerInfomation/>  */}
                </div>
            )}
        </div>
    );
};

export default GroupInfo;

import React, {useState} from "react";
import dayjs from 'dayjs';
import moment from 'moment';
import { DatePicker } from 'antd';
import 'antd/dist/reset.css';
import {useGlobal} from "reactn";
const { RangePicker } = DatePicker;
const dateFormat = 'DD/MM/YYYY';

const RangePickerFilter = () => {
    //Filter
    const [roomFilter, setRoomFilter] = useGlobal('roomFilter');

    const [selectedDate, setSelectedDate] = useState(null);

    const getRangePresets = () => {
        const today = dayjs();
        const yesterday = today.subtract(1, 'day');
        const startOfMonth = today.startOf('month');
        const endOfMonth = today.endOf('month');
        const startOfLastMonth = today.subtract(1, 'month').startOf('month');
        const endOfLastMonth = today.subtract(1, 'month').endOf('month');
        const startOfYear = today.startOf('year');
        const endOfYear = today.endOf('year');
        const startOfLastYear = today.subtract(1, 'year').startOf('year');
        const endOfLastYear = today.subtract(1, 'year').endOf('year');

        return [
            {
                label: 'Hôm nay',
                value: [today, today],
            },
            {
                label: 'Hôm qua',
                value: [yesterday, yesterday],
            },
            {
                label: '7 ngày qua',
                value: [today.subtract(7, 'day'), today],
            },
            {
                label: '30 ngày qua',
                value: [today.subtract(30, 'day'), today],
            },
            {
                label: 'Trong tháng này',
                value: [startOfMonth, endOfMonth],
            },
            {
                label: 'Trong tháng trước',
                value: [startOfLastMonth, endOfLastMonth],
            },
            {
                label: 'Năm nay',
                value: [startOfYear, endOfYear],
            },
            {
                label: 'Năm trước',
                value: [startOfLastYear, endOfLastYear],
            },
            {
                label: 'Tùy chỉnh',
                value: [null, null],
            },
        ];
    };

    const handleRangeChange = (dates, dateStrings) => {
        if (dates) {
            console.log({datee: JSON.stringify(dates)});
            setRoomFilter({...roomFilter, created_at: [dateStrings[0], dateStrings[1]]});
            setSelectedDate(dates);
        } else {
            console.log('Clear');
        }
    };

    return (
        <RangePicker 
            style={{ width: "300px" }}
            presets={getRangePresets()} 
            // defaultValue={[dayjs(dayjs().subtract(1, 'month'), dateFormat), dayjs(dayjs(), dateFormat)]}
            // defaultValue={roomFilter.created_at ? [moment(roomFilter.created_at[0]).format(dateFormat), moment(roomFilter.created_at[1]).format(dateFormat)] : []}
            value={(roomFilter && roomFilter.created_at?.length > 0) ? [dayjs(roomFilter.created_at[0], dateFormat), dayjs(roomFilter.created_at[1], dateFormat)] : null}
            // needConfirm
            placeholder={['Ngày bắt đầu', 'Ngày kết thúc']}
            onChange={handleRangeChange} 
            format={dateFormat}/>
    )
};

export default RangePickerFilter;

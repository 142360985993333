import axios from "axios";
import Config from "../../config";

const SendMessageNotification = params => {
    const {userSocialId, socialChanelId, templateId} = params
    return axios({
        method: "POST",
        url: (Config.url || '') + "/api/message/send-notification-message",
        data: {userSocialId, socialChanelId, templateId}
    });
};

export default SendMessageNotification;

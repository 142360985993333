import React, {useEffect, useState} from 'react';
import './Log.sass';
import getLogsApi from "../../actions/getLogs";
import { Table, Tag, Input, Button } from 'antd';
import ReactJson from 'react-json-view';

const LogViewer = () => {
    const [dataLog, setDataLog] = useState([]);
    const [brandList, setBrandList] = useState([]);
    const [keyword, setKeyword] = useState("");

    const urlParams = new URLSearchParams(window.location.search);
    const dateParam = urlParams.get('d'); 

    useEffect(() => {   
        fetchLog(dateParam);
    }, [dateParam]);

    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});

    const fetchLog = (date) => {
        getLogsApi(date).then(res => {
            let data = res.data.data;
            setDataLog(data);
            let brands = data.map((e) => e.brand);
            brands = [...new Set(brands.filter(item => item !== undefined))];
            let brandFormat = brands.map(item => ({ text: item, value: item }));

            setBrandList(brandFormat);
        });
    }

    const handleChange = (pagination, filters, sorter) => {
        console.log('Various parameters', pagination, filters, sorter);
        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };

    const columns = [
        {
            title: 'timestamp',
            dataIndex: 'timestamp',
            key: 'timestamp',
            width: '10%',
            ellipsis: true,
        },
        {
            title: 'level',
            dataIndex: 'level',
            key: 'level',
            width: '6%',
            render: (dataIndexValue, record) => (record.level === 'info' ? (<Tag color="success">{record.level}</Tag>) : (<Tag color="error">{record.level}</Tag>)),
            filters: [
                {
                    text: 'error',
                    value: 'error',
                },
                {
                    text: 'info',
                    value: 'info',
                },
            ],
            filteredValue: filteredInfo.level || null,
            onFilter: (value, record) => record.level.includes(value),
            sorter: (a, b) => a.level.length - b.level.length,
            sortOrder: sortedInfo.columnKey === 'level' ? sortedInfo.order : null,
        },
        {
            title: 'brand',
            dataIndex: 'brand',
            width: '6%',
            render: (dataIndexValue, record) => (<Tag color="processing">{record.brand}</Tag>),
            key: 'brand',
            filters: brandList,
            filteredValue: filteredInfo.brand || null,
            onFilter: (value, record) => record?.brand ? record.brand.includes(value) : [],
            ellipsis: true,
        },
        {
            title: 'message',
            dataIndex: 'message',
            key: 'message',
            width: '20%',
            filterSearch: true,
            onFilter: (value, record) => record.name.startsWith(value),
        },
        {
            title: 'data',
            dataIndex: 'data',
            key: 'data',
            filterSearch: true,
        },
        {
            title: 'json',
            dataIndex: 'json',
            key: 'json',
            render: (dataIndexValue, record) => <ReactJson src={record.json} name={null} quotesOnKeys={false} displayDataTypes={false} collapsed={2} />,
            filterSearch: true,
        },
    ];

    const onSubmitSearch = () => {
        const searchText = keyword.trim();

        if(searchText === ''){
            fetchLog(dateParam);
            return;
        }

        const regex = new RegExp(searchText, 'i');

        let result = dataLog.filter(e => regex.test(e.data));

        setDataLog(result);
    }

    const handleKeyPress = event => {
        if (event.keyCode === 13) {
            if (event.type === "keydown" && !event.shiftKey){
                onSubmitSearch();
                event.preventDefault();
            }
        }
    }

    return (
        <div className="report-wrapper">
            <div className='report-header'>
                <div className='container content-header'>
                    <div className='left-header'></div>
                    <div className='right-header'>
                        <div className='page-lists'>
                        </div>
                    </div>
                </div>
            </div>
            <div className="input-search text-right" style={{ margin: '10px' }}>
                <Input
                    style={{ width: '300px', marginRight: '5px' }}
                    placeholder="Nhập từ khóa để tìm kiếm..."
                    onChange={e => setKeyword(e.target.value)}
                    onKeyPress={handleKeyPress} 
                    onKeyDown={handleKeyPress}
                    value={keyword}
                />
                <Button type="primary" onClick={() => onSubmitSearch()}>Tìm kiếm</Button>
            </div>
            <div className='container page-statistic'>
                <Table columns={columns} dataSource={dataLog} pagination={{ pageSize: 15 }} onChange={handleChange} />
            </div>
        </div>
    );
}

export default LogViewer;

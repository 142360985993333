import React, {useState, useEffect} from 'react';
import "./BoxMessageTemplate.sass";
import GetTemplateByUserApi from "../../../api/campaign/GetTemplateByUser";
import ModalSendTemplate from "../../../pages/Campaign/components/Block/ModalSendTemplate"
import {useSelector} from "react-redux";
import { Card, Space, Collapse, List, Image, Tabs } from 'antd';

const BoxMessageTemplate = ({setShowDialog}) => {
    const room = useSelector(state => state.io.room);
    const [templates, setTemplates] = useState([]);
    const [templateNonAccept, setTemplateNonAccept] = useState([]);
    const [templateAccept, setTemplateAccept] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [templateId, setTemplateId] = useState("");

    useEffect(() => {
        getTemplateList();
    }, []);

    const getTemplateList = async () => {
        if(!room){
            return
        }

        setLoading(true);
        
        try{
            let res = await GetTemplateByUserApi(room.owner.userSocialId);
            let data = res.data.data;

            setTemplateAccept(data.filter(e => e.is_send && e.is_accept));
            setTemplateNonAccept(data.filter(e => e.is_send && !e.is_accept));
            setTemplates(data.filter(e => !e.is_send));
            setLoading(false);
        }
        catch(ex){
            console.log({ex});
            setLoading(false);
        }
    }

    const MessageNotification = ({data}) => {
        return (
            <List
                itemLayout="horizontal"
                dataSource={data}
                renderItem={(item, index) => (
                <List.Item onClick={() => showEditTemplate(item._id)} style={{ cursor: 'pointer' }}>
                    <List.Item.Meta
                    avatar={<Image style={{ width: '45px', marginRight: '10px', borderRadius: '5px' }} src={item.thumbnail} />}
                    title={<div style={{ cursor: 'pointer' }}>{item.template_name}</div>}
                    description={item.description}
                    />
                </List.Item>
                )}
            />
        )
    }

    const showEditTemplate = (id) => {
        setTemplateId(id);
        setShowModal(true);
    }

    const filterItems = [
        {
            key: '1',
            label: 'ĐÃ ĐỒNG Ý NHẬN TIN',
            children: <MessageNotification data={templateAccept}/>,
        },
        {
            key: '2',
            label: 'CHƯA ĐỒNG Ý NHẬN TIN',
            children: <MessageNotification data={templateNonAccept}/>,
        }
    ];

    const getTabItems = () => {
        let tabItems = [
            {
                label: 'ĐÃ GỬI',
                key: 'send',
                children: <Collapse items={filterItems} defaultActiveKey={['1', '2']} />,
            },
            {
                label: 'CHƯA GỬI',
                key: 'not_send',
                children: <MessageNotification data={templates}/>,
            }
        ];

        return tabItems;
    }

    return (
        <div className="box-template-wrap text-center">
            <ModalSendTemplate showModal={showModal} setShowModal={setShowModal} templateId={templateId}/>
            <Space direction="vertical" size={16}>
                <Card title="Chủ đề thông báo định kỳ" size="small" bordered={false} style={{ width: 500 }}>
                    <Tabs items={getTabItems()} />
                </Card>
            </Space>
        </div>
    );
}

export default BoxMessageTemplate;

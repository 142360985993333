import React, {useState, useEffect, useContext} from "react";
import './Room.sass';
import './RoomFeature.sass';
import './ButtonDirect.sass';
import 'bootstrap/dist/css/bootstrap.min.css';
import {useGlobal} from "reactn";
import {FiChevronDown, FiChevronUp} from "react-icons/fi";
import {Image} from "react-bootstrap";
import Notes from "./Notes";
import SocialProfile from "./SocialProfile";
import ChatInfomationTab from "./ChatInfomationTab";
import AdsInfo from "./AdsInfo";
import Loading from "./Loading";
import TagModal from "./TagModal";
import {useSelector} from "react-redux";
import store from "../../../store";
import updateMappingCustomer from "../../../actions/updateMappingCustomer";
import icons from "../../../assets"
import Actions from "../../../constants/Actions";
import getRooms from "../../../actions/getRooms";
import sendMessageApi from "../../../actions/message";
import addTagUserApi from "../../../actions/addTagUser";
import getListTagByUserApi from "../../../actions/getListTagByUser";
import { AppContext } from '../../../context/AppContext';
import { Card, Tag, Space, Select, Tabs } from 'antd';

const RoomFeature = ({userChat, setShowAlert, showAlertAction, hideAlertAction, detectCustomerAction, loadingCustomerInfo, customerInfo, isDetectCustomer, customerName}) => {
    const room = useSelector(state => state.io.room);
    const user = useGlobal('user')[0];
    const brand = useGlobal('brand')[0];
    const { i18n } = useContext(AppContext);

    const [loadingTag, setLoadingTag] = useState(false);

    //Filter
    const roomFilter = useGlobal('roomFilter')[0];
    
    const [showModalAddTag, setShowModalAddTag] = useGlobal('showModalAddTag');

    const [userSelectedTagGlobal, setUserSelectedTagGlobal] = useGlobal('userSelectedTag');
    const setUserActiveTagGlobal = useGlobal('userActiveTag')[1];

    const [loading, setLoading] = useState(false);
    const [showSocialProfile, setShowSocialProfile] = useState(false);
    const [showListAction, setShowListAction] = useState(true);

    useEffect(() => {

        window.addEventListener('message', function (event) {
            var data = event.data;
            if(data.func === 'addSuccessCustomerLead'){
                addSuccessCustomerLead(data.message);
            } else if(data.func === 'cancelCustomerLead'){
                cancelCustomerLead(data.message);
            } else if(data.func === 'cancelDeal'){
                cancelDeal(data.message);
            } else if(data.func === 'addSuccessCustomerDeal'){
                addSuccessCustomerDeal(data.message);
            } else if(data.func === 'cancelCustomer'){
                cancelCustomer(data.message);
            } else if(data.func === 'addSuccessCustomer'){
                addSuccessCustomer(data.message);
            } else if(data.func === 'cancelOrder'){
                cancelOrder(data.message);
            } else if(data.func === 'addSuccessOrder'){
                addSuccessOrder(data.message);
            } else if(data.func === 'cancelAppointment'){
                cancelAppointment(data.message);
            } else if(data.func === 'addSuccessAppointmentSchedule'){
                addSuccessAppointmentSchedule(data.message);
            } else if(data.func === 'cancelWork'){
                cancelWork(data.message);
            } else if(data.func === 'addSuccessManagerWork'){
                addSuccessManagerWork(data.message);
            }

            setLoading(false);
        });

    }, []);

    useEffect(() => {
        if(userChat){
            getListTagByUser(); 
        }
    }, [userChat]);

    const reloadRooms = () => {
        getRooms(roomFilter)
            .then(res => store.dispatch({type: Actions.SET_ROOMS, rooms: res.data.rooms}))
            .catch(err => console.log(err));
    }

    const handleSetLoading = () => {
        setLoading(true);

        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }

    const getListTagByUser = async () => {
        setLoadingTag(true);
        getListTagByUserApi({user_id: userChat._id}).then(res => {
            if(res.data.error === 0 && res.data.data){
                let result = res.data.data;
                setUserSelectedTagGlobal(result);
                const listTagShow = result && result.filter(e => e.isActive === true);
                setUserActiveTagGlobal(listTagShow);

                setLoadingTag(false);
            }
        }).catch(err => console.log('getListTagApi', err));
    }

    //Add CPO Customer
    const showAddCustomerLead = () => {
        handleSetLoading(true);
        window.parent.postMessage({
            'func': 'showAddCustomerLead',
            'message': {
                full_name : customerInfo?.firstName || "",
                ch_customer_id : customerInfo.mapping_id,
            }
        }, "*");
    }

    //Add order success
    const cancelCustomerLead = (data) => {
        setLoading(false);
        console.log('cancelCustomerLead')
    }

    //Add CPO customer success
    const addSuccessCustomerLead = (data) => {
        console.log('addSuccessCustomerLead', data);
        if(data && data.ch_customer_id){
            updateMappingCustomer(data).then(res => {
                console.log('res.update.mapping', res.data);
                if(res.data.error === 0 && res.data.data){
                    let result = res.data.data;
                    detectCustomerAction(result);
                }
            }).catch(err => console.log(err));
        }
    }

    const cancelDeal = () => {
        setLoading(false);
    }

    //Tạo cơ hội bán hàng
    const showAddCustomerDeal = () => {
        handleSetLoading(true);

        let data = {
            ch_customer_id : customerInfo.mapping_id,
            full_name : customerName
        };

        if(customerInfo.customer_id){
            data.object_id = customerInfo.customer_id;
            data.object_type = 'customer';
        }

        if(customerInfo.customer_lead_id){
            data.object_id = customerInfo.customer_lead_id;
            data.object_type = 'lead';
        }
        
        window.parent.postMessage({
            'func': 'showAddCustomerDeal',
            'message': data
        }, "*");
    }

    // Tạo cơ hội bán hàng thành công
    const addSuccessCustomerDeal = (data) => {
        // var data = {"dead_id":1, dead_code:"DEALS_2401202201","ch_customer_id":"1"}
        console.log('addSuccessCustomerDeal', data);
    }

    // Add customer
    const showAddCustomer = () => {
        // handleSetLoading(true);
        window.parent.postMessage({
            'func': 'showAddCustomer',
            'message': {
                ch_customer_id : customerInfo.mapping_id,
                // avatar : `${Config.url || ''}/api/images/${userChat.picture.shieldedID}/256/${brand}`,
                full_name : customerName
            }
        }, "*");
    }

    //Add order success
    const cancelCustomer = (data) => {
        setLoading(false);
    }

    // Add customer success
    const addSuccessCustomer = (data) => {
        console.log('addSuccessCustomer', data);
        if(data && data.ch_customer_id){
            updateMappingCustomer(data).then(res => {
                console.log('res.update.mapping', res.data);
                if(res.data.error === 0 && res.data.data){
                    let result = res.data.data;
                    detectCustomerAction(result);
                }
            }).catch(err => console.log(err));
        }
    }

    // Add tag
    const addTag = () => {
        // if(isDetectCustomer){
        //     let url = `https://${brand}.epoints.vn/customer-lead/tag`;
        //     window.open(url, "_blank");
        // }   
        // else{
        //     setShowModalAddTag(true);
        // }
        setShowModalAddTag(true);
    }

    // Add order
    const showAddOrder = () => {
        // handleSetLoading(true);
        let message = {
            ch_customer_id : customerInfo.mapping_id,
            full_name : customerName
        };

        if(customerInfo.customer_id){
            message.customer_id = customerInfo.customer_id;
        }

        window.parent.postMessage({
            'func': 'showAddOrder',
            'message': message
        }, "*");
    }

    //Add order success
    const cancelOrder = (data) => {
        setLoading(false);
    }

    //Add order success
    const addSuccessOrder = (data) => {
        // var data = {"order_id":1, 'order_code' : "123123","ch_customer_id":"1"}
    }

    //Tạo nhắc nhở
    const showPopupRemind = () => {
        handleSetLoading(true);
        window.parent.postMessage({
            'func': 'showPopupRemind',
            'message': {
                phone : customerInfo?.phone || null,
                ch_customer_id : customerInfo.mapping_id,
            }
        }, "*");
    }


    // Tạo lịch hẹn
    const showAppointmentSchedule = () => {
        handleSetLoading(true);
        window.parent.postMessage({
            'func': 'showAppointmentSchedule',
            'message': {
                phone : customerInfo?.phone || null,
                ch_customer_id : customerInfo.mapping_id,
                customer_id : customerInfo?.customer_id,
            }
        }, "*");
    }

    //Add order success
    const cancelAppointment = (data) => {
        setLoading(false);
    }

    // Tạo lịch hẹn thành công
    const addSuccessAppointmentSchedule = async (data) => {
        // var data = {"customer_appointment_id":1, 'customer_appointment_code' : "123123","ch_customer_id":"1"}
        // alert('addSuccessAppointmentSchedule')
        if(data && data.customer_appointment_id){
            console.log('addSuccessAppointmentSchedule', data);
            sendMessageAppointment(data);
        }
    }

    const sendMessageAppointment = async (data) => {
        let roomID = sessionStorage.getItem('room_id')
        if(roomID && user){
            const noted = data.note ? `\n⭕ ${data.note}` : '';
            const message = `${i18n.t('confirm_appointment')}:\n👤 ${data.customer_name}\n📞 ${data.customer_phone}\n☑️${data.service_name}\n🗓 ${data.datetime_appointment}\n⏰ ${data.hour_appointment}${noted}`;
            let response = await sendMessageApi({roomID, authorID: user.id, content: message, contentType: 'text', action : 'message'});
            store.dispatch({type: Actions.MESSAGE, message: response.data.message});
            reloadRooms();
        }
        else{
            console.log('sendMessageAppointment error');
        }
    }

    //Add order success
    const cancelWork = (data) => {
        setLoading(false);
    }

    // Tạo quản lý công việc
    const showAddManagerWork = () => {
        handleSetLoading(true);
        window.parent.postMessage({
            'func': 'showAddManagerWork',
            'message': {
                customer_id: customerInfo?.customer_id || null,
                customer_lead_id: customerInfo?.customer_lead_id || null,
                ch_customer_id: customerInfo.mapping_id
            }
        }, "*");
    }

    // Tạo quản lý công việc thành công
    const addSuccessManagerWork = (data) => {
        // var data = {"manage_work_id":1, 'manage_work_code' : "123123","ch_customer_id":"1"}
        if(data.manage_work_id){
            console.log('addSuccessManagerWork', data);
        }
    }

    const toggleSocialProfile = () => {
        setShowSocialProfile(e => !e);
    }

    const handlePreventEvents = () => {
        let className = '';

        if(loading){
            return 'prevent-events';
        }

        if(userChat && userChat.isBlocked){
            return 'prevent-events';
        }

        return className;
    }

    // Kiểm tra xem là KH hay KH tiềm năng
    const detectIsCpoCustomer = (customerInfo && customerInfo.type === 'cpo') ? true : false;

    const getOptionTag = () => {
        let options = [];
        if(userSelectedTagGlobal && userSelectedTagGlobal.length){
            options = userSelectedTagGlobal.map(e => (
                {label: e.name, value: e.name, id: e.tag_id, color: e.color || '#0067ac'}
            ));
        }

        console.log({options});

        return options;
    }

    const getSelectedTag = () => {
        let selectedValue = [];
        if(userSelectedTagGlobal && userSelectedTagGlobal.length){
            let filtered = userSelectedTagGlobal.filter(el => el.isActive === true);
            selectedValue = filtered.map(e => e.name);
        }

        return selectedValue;
    }

    const handleChangeTag = (value, option) => {
        let tagIds = option.map(e => e.id);
        addTagUserApi({user_id: userChat._id, tag_ids: tagIds})
            .then(res => {
                setShowModalAddTag(false);
                if(res.data.error === 0){
                    getAllTag();
                }
            }).catch(err => console.log(err));
    }

    const getAllTag = () => {
        getListTagByUserApi({user_id: userChat._id}).then(res => {
            if(res.data.error === 0 && res.data.data){
                let result = res.data.data;
                setUserSelectedTagGlobal(result);
                const listTagShow = result && result.filter(e => e.isActive === true);
                setUserActiveTagGlobal(listTagShow);
            }
        }).catch(err => console.log('getListTagApi', err));
    }

    const customTagRender = (props) => {
        const { label, value, closable, onClose } = props;

        let color = '#0067ac';

        if(userSelectedTagGlobal && userSelectedTagGlobal.length){
            let findTag = userSelectedTagGlobal.find(e => e.name === label);
            color = findTag ? findTag.color : '#0067ac';
        }

        return (
            <Tag
                className="tag-default-select"
                closable={closable}
                onClose={onClose}
                style={{ marginRight: 3, background: color || '#0067ac', color: '#fff', fontWeight: '600', border: 'none', padding: '2px 5px', marginTop: '2px', marginBottom: '2px' }}
            >
                {label}
            </Tag>
        );
    };

    const ChatFeature = () => {
        return (
            <div className="action-group">
                <div className={`detail-action-group`}>
                    {loadingCustomerInfo && <Loading/>}
                    {!loadingCustomerInfo && showListAction && (
                        <>
                        <div style={{ marginBottom: '10px' }}>
                            <Card size="small" style={{ width: '100%', cursor: 'pointer' }} extra={<div style={{ color: '#fff' }} onClick={() => addTag()}>Quản lý nhãn</div>} bordered={false} title="Gắn nhãn">
                                <Select
                                    mode="multiple"
                                    style={{
                                        width: '100%',
                                    }}
                                    placeholder="Chọn nhãn"
                                    defaultValue={getSelectedTag()}
                                    onChange={handleChangeTag}
                                    options={getOptionTag()}
                                    tagRender={customTagRender}
                                    optionRender={(option) => (
                                        <Space>
                                            <div style={{ width: '18px', height: '18px', borderRadius: '50%', background: `${option.data.color}` }}></div>
                                            {option.data.label}
                                        </Space>
                                    )}
                                />
                            </Card>
                        </div>
                        {!isDetectCustomer ? (
                            <>
                                {/* {(routeLists && routeLists.includes('admin.order.add')) && ()} */}
                                <div className={`detail-action-item action-event ${handlePreventEvents()}`} onClick={() => showAddOrder()}><Image width="20" src={icons.AddOrder}/><span>{i18n.t('create_order')}</span></div>
                                {/* {(routeLists && routeLists.includes('admin.customer.add')) && ()} */}
                                <div className={`detail-action-item action-event ${handlePreventEvents()}`} onClick={() => showAddCustomer()}><Image width="20" src={icons.AddCustomer}/><span>{i18n.t('create_customer')}</span></div>
                                {/* {(routeLists && routeLists.includes('customer-lead.create')) && ()} */}
                                <div className={`detail-action-item action-event ${handlePreventEvents()}`} onClick={() => showAddCustomerLead()}><Image width="20" src={icons.AddCustomerPotential}/><span>{i18n.t('create_customer_lead')}</span></div>
                            </>
                        ):(
                            <>
                                {detectIsCpoCustomer ? (
                                    // (routeLists && routeLists.includes('admin.customer.add')) && ()
                                    <div className={`detail-action-item action-event ${handlePreventEvents()}`} onClick={() => showAddCustomer()}><Image width="20" src={icons.AddCustomer}/><span>{i18n.t('create_customer')}</span></div>
                                ) : (
                                    <>
                                    {/* {(routeLists && routeLists.includes('admin.order.add')) && ()} */}
                                    <div className={`detail-action-item action-event ${handlePreventEvents()}`} onClick={() => showAddOrder()}><Image width="20" src={icons.AddOrder}/><span>{i18n.t('create_order')}</span></div>
                                    {/* {(routeLists && routeLists.includes('admin.customer_appointment.submitModalAdd')) && ()} */}
                                    <div className={`detail-action-item action-event ${handlePreventEvents()}`} onClick={() => showAppointmentSchedule()}><Image width="20" src={icons.Calendar}/><span>{i18n.t('create_appointment')}</span></div>
                                    </>
                                )}
                                {/* {(routeLists && routeLists.includes('customer-lead.customer-deal.create')) && ()} */}
                                <div className={`detail-action-item action-event ${handlePreventEvents()}`} onClick={() => showAddCustomerDeal()}><Image width="20" src={icons.Sales}/><span>{i18n.t('create_deal')}</span></div>
                            </>
                        )}
        
                        {/* {(routeLists && routeLists.includes('manager-work.detail-remind')) && ()}                 */}
                        <div className={`detail-action-item action-event ${handlePreventEvents()}`} onClick={() => showPopupRemind()}><Image width="20" src={icons.AddRemind}/><span>{i18n.t('create_reminder')}</span></div>
                        
                        {/* {(routeLists && routeLists.includes('manager-work.detail.show-popup-work-child')) && ()} */}
                        <div className={`detail-action-item action-event ${handlePreventEvents()}`} onClick={() => showAddManagerWork()}><Image width="20" src={icons.AddTask}/><span>{i18n.t('create_task')}</span></div>
                        </>
                    )}
                </div>
            </div>
        )
    }

    const getTabItems = () => {
        let tabItems = [
            {
                label: i18n.t('feature'),
                key: 'feature',
                children: <ChatFeature/>,
            },
            {
                label: i18n.t('chat_infomation'),
                key: 'chat_infomation',
                children: <ChatInfomationTab room={room}/>,
            },
            {
                label: i18n.t('note'),
                key: 'note',
                children: <Notes setShowAlert={setShowAlert} showAlertAction={showAlertAction} hideAlertAction={hideAlertAction}/>,
            }
        ];

        return tabItems;
    }

    return (
        <div className="block-rf">
            {room && room.source !== 'client' && (
                <div className="action-group">
                    <div className="action-title" onClick={() => toggleSocialProfile()}>
                        <span>{i18n.t('social_profile')}</span>
                        <span>{showSocialProfile ? <FiChevronUp/> : <FiChevronDown/>}</span>
                    </div>

                    <div className={`detail-action-group`}>
                        {showSocialProfile && <SocialProfile/>}
                    </div>

                    <div className={`detail-action-group`}>
                        {showSocialProfile && room && room.source === 'facebook' && room.lastAdReferral && <AdsInfo/>}
                    </div>
                </div>
            )}

            <div className="action-tabs">
                <Tabs items={getTabItems()} />
            </div>

            {showModalAddTag && 
                <TagModal 
                    userChat={userChat} 
                    showModalAddTag={showModalAddTag} 
                    setShowModalAddTag={setShowModalAddTag} 
                    showAlertAction={showAlertAction}
            />}
        </div>
    );
};

export default RoomFeature;

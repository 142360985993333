import React, {useEffect, useState, useContext} from 'react';
import {useSelector} from "react-redux";
import './ZaloMessTransModal.sass';
import { AppContext } from "../../../context/AppContext";
import {useGlobal} from "reactn";
import icons from "../../../assets"
import store from "../../../store";
import Actions from "../../../constants/Actions";
import { Modal, Card, Button, notification, message, Popconfirm, Image, Space, Col, Row } from 'antd';
import sendMessageTransactionApi from "../../../actions/sendMessageTransaction";
import messageSystemApi from "../../../actions/messageSystem";
import 'antd/dist/reset.css';

const ZaloMessTransModal = ({showModal, setShowModal}) => {
    const { i18n } = useContext(AppContext);
    const user = useGlobal('user')[0];
    const room = useSelector(state => state.io.room);

    const [opens, setOpens] = useState([]);
    const [loadings, setLoadings] = useState([]);

    let userChat = room ? room?.owner : null;

    useEffect(() => {

    }, []);

    const showPopconfirm = (index) => {
        handleConfirmOpen(index, true);
    };
    
    const handleCancel = (index) => {
        handleConfirmOpen(index, false);
    };

    const handleConfirmOpen = (index, open) => {
        setOpens((prevOpens) => {
            const newOpens = [...prevOpens];
            newOpens[index] = open;
            return newOpens;
        });
    }

    const handleConfirmLoading = (index, loading) => {
        setLoadings((prevLoadings) => {
            const newLoadings = [...prevLoadings];
            newLoadings[index] = loading;
            return newLoadings;
        });
    }

    const sendMessageTransaction = (index, type) => {

        let typeName = '';

        if(type === 'rating'){
            typeName = 'Tin đánh giá';
        }
        else if(type === 'promotion'){
            typeName = 'Tin khuyến mãi/sự kiện'
        }

        handleConfirmLoading(index, true)
        sendMessageTransactionApi({channel_id: room.channel._id, user_social_id: room.owner.userSocialId, type})
            .then(res => {
                handleConfirmOpen(index, false);
                handleConfirmLoading(index, false)
                if(res.data.error === 0 && res.data.data){
                    sendMessageSystem(`Đã gửi tin tương tác: ${typeName}`);
                    message.success('Gửi tin nhắn thành công!');
                }
                else{
                    message.error(`Gửi tin nhắn thất bại!. ${res.data.message}`, 5);
                }
            }).catch(err => console.log('sendMessageRating', err));
    };

    const sendMessageSystem = async (text) => {
        let finalText = text;
        let resMess;

        let messageData = { roomID: room._id, authorID: user.id, content: finalText, type: 'system', action : 'message'};
        resMess = await messageSystemApi(messageData);
        resMess = resMess?.data;

        if(!resMess){
            return;
        }

        let newMessage = resMess.data.message;

        if(resMess.error){
            newMessage = resMess.data.message;
            newMessage.error_message = resMess.message;
        }

        store.dispatch({type: Actions.MESSAGE, message: newMessage});
        // reloadRooms();
    };

    const getTitlePopup = () => {
        return `Gửi tin nhắn tương tác đến ${userChat.firstName}`;
    };

    const handleCancelModal = () => {
        setShowModal(false);
    };

    const getPopupConfirm = (index, type) => {

        return (
            <Popconfirm
                title={`Lưu ý:`}
                description="Đây là tin nhắn có tính phí (165đ/tin)."
                open={opens[index]}
                onConfirm={() => sendMessageTransaction(index, type)}
                okButtonProps={{ loading: loadings[index] }}
                onCancel={handleCancel}
            >
                <Button type="primary" onClick={showPopconfirm}><strong>Gửi tin</strong></Button>
            </Popconfirm>
        )
    }

    return (
        <Modal width={1300} title={getTitlePopup()} open={showModal} onOk={handleCancelModal} onCancel={handleCancelModal}>
            <Space direction="vertical" size={24} style={{width: "100%", marginTop: "20px"}}>
                <Row gutter={24} className="mb-2">
                    <Col span={6}>
                        <Card title={"Tin đánh giá"} className="card-mess-trans">
                            <Space direction="vertical" size={12} align="center" style={{width: "100%"}}>
                                <span>Tin nhắn định kỳ</span>
                                <Image src={icons.ZaloSendRating}/>
                                {getPopupConfirm(0, 'rating')}
                            </Space>
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card title={"Tin khuyến mãi/Sự kiện"} className="card-mess-trans">
                            <Space direction="vertical" size={12} align="center" style={{width: "100%"}}>
                                <span>Tin nhắn định kỳ</span>
                                <Image src={icons.ZaloSendPromotion}/>
                                {getPopupConfirm(1, 'promotion')}
                            </Space>
                        </Card>
                    </Col>
                    <Col span={6}>
                    </Col>
                    <Col span={6}></Col>
                </Row>
            </Space>
        </Modal>
    );
}

export default ZaloMessTransModal;

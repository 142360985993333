import React, {useState, useEffect, useContext} from "react";
import './Room.sass';
import './GroupFeature.sass';
import './ButtonDirect.sass';
import 'bootstrap/dist/css/bootstrap.min.css';
import {useGlobal} from "reactn";
import {FiChevronDown, FiChevronUp} from "react-icons/fi";
import Notes from "./Notes";
import MemberGroup from "./MemberGroup";
import ChatInfomationTab from "./ChatInfomationTab";
import TagModal from "./TagModal";
import {useSelector} from "react-redux";
import { AppContext } from '../../../context/AppContext';
import { Tabs } from 'antd';

const GroupFeature = ({userChat, setShowAlert, showAlertAction, hideAlertAction, detectCustomerAction, loadingCustomerInfo, customerInfo, isDetectCustomer, customerName}) => {
    const room = useSelector(state => state.io.room);

    const { i18n } = useContext(AppContext);
    
    const [showModalAddTag, setShowModalAddTag] = useGlobal('showModalAddTag');

    const [showMemberGroup, setShowMemberGroup] = useState(false);

    const toggleSocialProfile = () => {
        setShowMemberGroup(e => !e);
    }

    const getTabItems = () => {
        let tabItems = [
            {
                label: i18n.t('chat_infomation'),
                key: 'chat_infomation',
                children: <ChatInfomationTab room={room}/>,
            },
            {
                label: i18n.t('note'),
                key: 'note',
                children: <Notes setShowAlert={setShowAlert} showAlertAction={showAlertAction} hideAlertAction={hideAlertAction}/>,
            }
        ];

        return tabItems;
    }

    return (
        <div className="block-rf">
            {room && room.source !== 'client' && (
                <div className="action-group">
                    <div className="action-title" onClick={() => toggleSocialProfile()}>
                        <span>Thành viên ({room.people?.length || 0})</span>
                        <span>{showMemberGroup ? <FiChevronUp/> : <FiChevronDown/>}</span>
                    </div>

                    <div className={`detail-action-group`}>
                        {showMemberGroup && <MemberGroup/>}
                    </div>
                </div>
            )}

            <div className="action-tabs">
                <Tabs items={getTabItems()} />
            </div>

            {showModalAddTag && 
                <TagModal 
                    userChat={userChat} 
                    showModalAddTag={showModalAddTag} 
                    setShowModalAddTag={setShowModalAddTag} 
                    showAlertAction={showAlertAction}
            />}
        </div>
    );
};

export default GroupFeature;

import React, {useEffect, useState} from 'react';
import { Table, Input, Button} from 'antd';
import { Link } from 'react-router-dom';
import campaignApi from "../../../../api/campaign.api";
import { FiPlus, FiTrash2 } from 'react-icons/fi';
import { Switch, Image, Tag, message, Popconfirm } from 'antd';
import Config from "../../../../config";
import {useGlobal} from "reactn";
import moment from "moment";
import { useHistory } from 'react-router-dom';

const ScheduleMessagePage = () => {
    const [scheduleTemplates, setScheduleTemplates] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [loading, setLoading] = useState(false);
    let brand = useGlobal('brand')[0];
    const history = useHistory();

    useEffect(() => {
        getScheduleMessage();
    }, []);

    const getScheduleMessage = async () => {
        setLoading(true);
        
        try{
            let res = await campaignApi.getMarketingMessage();
            let data = res.data.data;

            const schedule = data.filter(e => e.status === 'new')
            const remainTemplate = data.filter(e => e.status !== 'new')
            setTemplates(remainTemplate);
            setScheduleTemplates(schedule);
            setLoading(false);
        }
        catch(ex){
            setLoading(false);
        }
    }

    const removeSchedule = async (schedule_id) => {
        const response = await campaignApi.removeScheduleMessage({schedule_id});
        let result = response.data;

        if(!result.error){
            message.success('Xóa lịch gửi tin nhắn thành công!')
            history.push('/campaign/schedule')
        }
        else{
            message.error('Xóa lịch gửi tin nhắn thất bại!')
        }
    }

    const getAvatar = (record) => {
            let name = record?.customer_name;
            let avatar = record?.avatar;
    
            if(avatar){
                return (
                    <div className='d-flex align-items-center'>
                        <div className='image-box'>
                            <img width="50" src={`${Config.url || ''}/api/images/${avatar}/256/${brand}`} alt=""/>
                        </div>
                        <Link to={`/room/${record.id}`} className="un-link">{name}</Link>
                    </div>
                )
            }
            else{
                return (
                    <div className='d-flex align-items-center'>
                        <div className='image-box'>
                            <div className="img">{name ? name.substr(0,1).toUpperCase() : ""}</div>
                        </div>
                        <Link to={`/room/${record.id}`} className="un-link">{name}</Link>
                    </div>
                )
            }
        }

    const columns = [
        {
            title: 'Gửi đến',
            dataIndex: 'customer_name',
            key: 'customer_name',
            ellipsis: true,
            width: '10%',
            render: (dataIndexValue, record) => (getAvatar(record))
        },
        {
            title: 'Tên mẫu',
            dataIndex: 'template_name',
            key: 'template_name',
            ellipsis: true,
            width: '30%',
            render: (dataIndexValue, record) => (<><Image style={{ width: '50px', marginRight: '10px', borderRadius: '5px' }} src={record.thumbnail} alt=""/> <span><Tag color="cyan">{record.content_type}</Tag>{record.template_name}</span></>)
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            width: '7%',
            render: (dataIndexValue, record) => (<Tag color="#108ee9">Đã lên lịch</Tag>)
        },
        {
            title: 'Sẽ gửi vào lúc',
            dataIndex: 'send_at_format',
            key: 'send_at_format',
            align: 'center',
            width: '7%',
        },
        {
            title: 'Hành động',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            width: '7%',
            render: (dataIndexValue, record) => (<Popconfirm
                title="Xóa đặt lịch"
                description="Bạn có muốn xóa đặt lịch gửi tin nhắn này?"
                onConfirm={() => removeSchedule(record._id)}
                okText="Đồng ý"
                cancelText="Hủy"
            >
                <FiTrash2 style={{ cursor: 'pointer', color: 'red' }}/>
            </Popconfirm>)
        },
    ];

    const remainColumns = [
        {
            title: 'Gửi đến',
            dataIndex: 'customer_name',
            key: 'customer_name',
            ellipsis: true,
            width: '10%',
            render: (dataIndexValue, record) => (getAvatar(record))
        },
        {
            title: 'Tên mẫu',
            dataIndex: 'template_name',
            key: 'template_name',
            ellipsis: true,
            width: '30%',
            render: (dataIndexValue, record) => (<><Image style={{ width: '50px', marginRight: '10px', borderRadius: '5px' }} src={record.thumbnail} alt=""/> <span><Tag color="cyan">{record.content_type}</Tag>{record.template_name}</span></>)
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            width: '7%',
            render: (dataIndexValue, record) => (record.status === 'success' ? <Tag color="success">Đã gửi</Tag> : <Tag color="error">Gửi thất bại</Tag>)
        },
        {
            title: 'Đã gửi vào lúc',
            dataIndex: 'send_at_format',
            key: 'send_at_format',
            align: 'center',
            width: '7%',
        }
    ];

    return (
        <>
        <div className=''>
            <div className="card">
                {/* <div className="header">
                    <div className="title-desc">
                        <div className="title">Đã lên lịch</div>
                    </div>
                    <div className='interaction'></div>
                </div> */}
                <div className="content">
                    <div className="chart-container">
                        <div style={{ textAlign: 'right', marginBottom: '15px' }}>
                            <Link to="/campaign/config-send">
                                <Button type="primary"><FiPlus/> Cấu hình gửi tin</Button>
                            </Link>
                        </div>
                        <div>
                            <div className="mb-3"><strong>Đã lên lịch</strong></div>
                            <Table columns={columns} loading={loading} dataSource={scheduleTemplates} pagination={{ pageSize: 10 }} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="card mt-3">
                <div className="content">
                    <div className="chart-container">
                        <div>
                            <div className="mb-3"><strong>Đã gửi</strong></div>
                            <Table columns={remainColumns} loading={loading} dataSource={templates} pagination={{ pageSize: 10 }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default ScheduleMessagePage;

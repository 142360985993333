import axios from "axios";
import Config from "../config";

const campaignApi = {
    getMessageTemplate: (keyword = '') => {
        return axios({
            method: "GET",
            url: (Config.url || '') + "/api/template",
            data: {keyword}
        });
    },

    getMessageNotification: (keyword = '') => {
        return axios({
            method: "POST",
            url: (Config.url || '') + "/api/message-notification",
            data: {keyword}
        });
    },

    getNotificationIsAccept: () => {
        return axios({
            method: "POST",
            url: (Config.url || '') + "/api/message-notification/get-is-accept",
            data: {}
        });
    },

    getTemplateDetail: (params) => {
        const {templateId} = params
        return axios({
            method: "POST",
            url: (Config.url || '') + "/api/template/detail",
            data: {template_id: templateId}
        });
    },

    configSendMessageCampaign: (params) => {
        return axios({
            method: "POST",
            url: (Config.url || '') + "/api/template/config-send",
            data: params
        });
    },

    getMarketingMessage: (params) => {
        return axios({
            method: "POST",
            url: (Config.url || '') + "/api/campaign/get-marketing-message",
            data: params
        });
    },

    removeScheduleMessage: (params) => {
        return axios({
            method: "POST",
            url: (Config.url || '') + "/api/campaign/remove-schedule-message",
            data: params
        });
    },

    removeMessageTemplate: (params) => {
        return axios({
            method: "POST",
            url: (Config.url || '') + "/api/template/remove",
            data: params
        });
    }
};

export default campaignApi;
import React, {useState} from 'react';
import "./TopBar.sass";
import {
    FiSettings,
    FiLayers,
} from 'react-icons/fi';
import {useGlobal} from "reactn";
import {useSelector} from "react-redux";
import {Image} from "react-bootstrap";
import facebookIcon from "../../../assets/facebook.png";
import zaloIcon from "../../../assets/zalo.png";
import clientChatIcon from "../../../assets/chat-client.svg";
import homeIcon from "../../../assets/chat-all.svg";
import SettingModal from "./SettingModal";
import ReportModal from "./ReportModal";
import { useHistory } from 'react-router-dom';

const TopBar = ({setDisableLoadMore}) => {
    const [socialActive, setSocialActive] = useGlobal('socialActive');
    const setPageCurrentRoom = useGlobal('pageCurrentRoom')[1];
    const notificationsUser = useSelector(state => state.io.notificationsUser);
    const [showModalSetting, setShowModalSetting] = useState(false);
    const [showModalReport, setShowModalReport] = useState(false);
    const history = useHistory();

    //Filter
    const [roomFilter, setRoomFilter] = useGlobal('roomFilter');

    const handleSetChatType = (social = '') => {
        setDisableLoadMore(false);
        setPageCurrentRoom(1);
        setSocialActive(social); // Set type social active
        setRoomFilter({...roomFilter, channel_id: '', source: social});
    }

    return (
        <div className="nav-bar uk-flex">
            <div className="topbar-left">
                <div className={`item${!socialActive ? ' active' : ''}`} onClick={() => handleSetChatType()}>
                    <div className="icon">
                        <Image width="40" style={{ padding: "5px" }} src={homeIcon}/>
                        {(notificationsUser && notificationsUser.total > 0) && (
                            <span className="nav-noti nav-chat-noti">{notificationsUser.total > 99 ? '99+' : notificationsUser.total}</span>
                        )}
                    </div>
                </div>
                <div className={`item${socialActive === 'client' ? ' active' : ''}`} onClick={() => handleSetChatType('client')}>
                    <div className="icon">
                        <Image width="37" src={clientChatIcon}/>
                        {(notificationsUser && notificationsUser.client > 0) && (
                            <span className="nav-noti nav-chat-noti">{notificationsUser.client > 99 ? '99+' : notificationsUser.client}</span>
                        )}
                    </div>
                </div>
                <div className={`item${socialActive === 'facebook' ? ' active' : ''}`} onClick={() => handleSetChatType('facebook')}>
                    <div className="icon">
                        <Image width="40" src={facebookIcon}/>
                        {(notificationsUser && notificationsUser.facebook > 0) && (
                            <span className="nav-noti nav-chat-noti noti-social">{notificationsUser.facebook > 99 ? '99+' : notificationsUser.facebook}</span>
                        )}
                    </div>
                </div>
                <div className={`item${socialActive === 'zalo' ? ' active' : ''}`} onClick={() => handleSetChatType('zalo')}>
                    <div className="icon">
                        <Image width="40" src={zaloIcon}/>
                        {(notificationsUser && notificationsUser.zalo > 0) && (
                            <span className="nav-noti nav-chat-noti noti-social">{notificationsUser.zalo > 99 ? '99+' : notificationsUser.zalo}</span>
                        )}

                    </div>
                </div>
            </div>
            <div className="topbar-right text-right mt-2 cursor-pointer d-flex justify-content-end">
                <div className="topbar-config" style={{ marginRight: '10px' }} onClick={() => history.push('/campaign/message-notification')}>
                    <FiLayers size={22} color={'#0067ac'}/>
                </div>
                <div className="topbar-config" onClick={() => setShowModalSetting(true)}>
                    <FiSettings size={22} color={'#0067ac'}/>
                </div>
            </div>
            {showModalSetting && 
                <SettingModal 
                    showModalSetting={showModalSetting} 
                    setShowModalSetting={setShowModalSetting} 
                />}

            {showModalReport && 
                <ReportModal 
                    showModalReport={showModalReport} 
                    setShowModalReport={setShowModalReport} 
                />}
        </div>
    );
}

export default TopBar;

import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
    ProductOutlined,
    BellOutlined,
    NotificationOutlined
} from '@ant-design/icons';

const MenuCampaign = () => {
    const location = useLocation();

    const getActiveClass = (path) => {
        return location.pathname === path ? 'item-statistic-selected' : '';
    }

    return (
        <div className='page-statistic-menu'>
            <span className="title-statistic">Chiến dịch</span>
            <Link to="/campaign/message-template" className={`item-statistic ${getActiveClass('/campaign/message-template')}`}>
                <NotificationOutlined style={{ fontSize: '20px'}}/>
                <span className="setting-title">Tin nhắn marketing</span>
            </Link>
            <Link to="/campaign/message-notification" className={`item-statistic ${getActiveClass('/campaign/message-notification')}`}>
                <ProductOutlined style={{ fontSize: '20px'}}/>
                <span className="setting-title">Tin nhắn định kỳ</span>
            </Link>
            <Link to="/campaign/schedule" className={`item-statistic ${getActiveClass('/campaign/schedule')} ${getActiveClass('/campaign/config-send')}`}>
                <BellOutlined style={{ fontSize: '20px'}} />
                <span className="setting-title">Cấu hình gửi tin</span>
            </Link>
        </div>
    );
};

export default MenuCampaign;
